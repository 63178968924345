<template>
  <div class="usage-detail__table-container">
    <div v-if="loadState === undefined" class="table-loading">Loading</div>
    <div v-if="loadState === 'empty'" class="usage-detail__error-container">No daily historical usage data available.
    </div>
    <div v-if="loadState == 'error'" class="usage-detail__error-container"><p>An unexpected error occurred trying to read
      daily historical usage data, please try again later.</p></div>
    <table v-if="loadState === 'complete'" class="usage-detail__hourly-table">
      <thead>
        <tr>
        <th class="heading-cell time">Time</th>
        <th v-if="isNetMetered" class="heading-cell grow-basis-10">Total Home Consumption</th>
        <th v-if="isNetMetered" class="heading-cell grow-basis-10">Total Generation</th>
        <th v-if="!isNetMetered && !hasTOU" class="heading-cell grow-basis-20">Consumption</th>
        <th v-if="isNetMetered && !hasTOU" class="heading-cell grow-basis-20">Consumed From Grid</th>
        <th v-if="hasTOU" class="heading-cell grow-basis-10">Time Of Use</th>
        <th class="heading-cell">Temp.</th>
        <th class="heading-cell">Conditions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="usage of hourlyUsageData" :key="usage.date.getTime()">
          <td class="usage-detail__usage-date">{{ convertToTimeRange(usage.date)}}
          </td>

          <td v-if="isNetMetered" class="usage-detail__data-series" data-series="totalEnergyUsed">
      {{ usage.totalEnergyUsed | numberCommaSingleDecimal }} <span class="unit">kWh</span>
      <Usage-Consumption-Bar-CSS :series1="usage.totalEnergyUsed" :maxUsageValue="maxDailyUsageValue" />
          <span class="notice" v-if="showPeakEventFlag(usage)">Battery usage by grid may show as<br/>negative usage or generation</span>

    </td>
    <td v-if="isNetMetered" class="usage-detail__data-series" data-series="generation">
      {{ usage.generation | numberCommaSingleDecimal }} <span class="unit">kWh</span>
            <span v-if="usage.generation > 0 || usage.returnedGeneration > 0" class="sub-series">{{ getGenerationToHome(usage) | numberCommaSingleDecimal }} <span class="unit">kWh to home,</span> {{ usage.returnedGeneration | numberCommaSingleDecimal }} <span class="unit">kWh to grid</span></span>

      <Usage-Consumption-Bar-CSS :series1="getGenerationToHome(usage)" :series2="usage.returnedGeneration" :maxUsageValue="maxDailyUsageValue" />
    </td>
    <td v-if="!hasTOU" class="usage-detail__data-series" data-series="consumedTotal">
      {{ usage.consumedTotal | numberCommaSingleDecimal }} <span class="unit">kWh</span>
      <Usage-Consumption-Bar-CSS :series1="usage.consumedTotal" :maxUsageValue="maxDailyUsageValue" />
    </td>
    <td v-if="hasTOU" class="usage-detail__data-series" data-series="consumedTOU">
      {{ usage.consumedTotal | numberCommaSingleDecimal }} <span class="unit">kWh</span>
      <span v-if="usage.consumedTotal > 0" class="sub-series">{{ getOnPeakUsage(usage) | numberCommaSingleDecimal }} <span class="unit">kWh Peak,</span> {{ getOffPeakUsage(usage) | numberCommaSingleDecimal }} <span class="unit">kWh Off Peak</span></span>
      <Usage-Consumption-Bar-CSS :series1="getOnPeakUsage(usage)" :series2="getOffPeakUsage(usage)" :maxUsageValue="maxDailyUsageValue" />
    </td>



          <td>{{ getTemperature(usage).avg ? getTemperature(usage).avg + '\u00B0F' : "" }}</td>
          <td>
            <img v-if="usage.weatherIcon && usage.weatherIcon != ''" class="usage-detail__weather-image"
              :src="weatherImagePath + usage.weatherIconImageUrl" :alt="usage.weatherIconImageUrl" />
          </td>
        </tr>
      </tbody>
      </table>
    </div>
</template>

<script>
import { format, getHours, parseISO, addMinutes } from "date-fns";
import { ToServerDateTruncate, DumpError } from "../../../../utilities";
import GMPAPI from "../../../../services/gmpapi";
import usageTableUtil from "../../../mixins/UsageTableUtil";
import UsageConsumptionBarCSS from "../usageshared/UsageConsumptionBarCSS";

export default {
  name: "UsageDetailHourlyTable",
  mixins: [usageTableUtil],
  components: {
    UsageConsumptionBarCSS
  },
  props: {
    usageDate: Date,
    isNetMetered: Boolean,
    hasTOU: Boolean,
    hasGenerationRecordsPresent: Boolean
  },
  data() {
    return {
      hourlyUsageData: undefined,
      loadState: undefined,
      maxDailyUsageValue: undefined,
      VermontSunriseSusetHours: [
      [7,17],
      [6,17],
      [5,18],
      [6,19],
      [5,19],
      [5,20],
      [5,20],
      [6,19],
      [6,19],
      [7,18],
      [7,16],
      [7,16]
      ]
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },

  },
  async mounted() {
    const accountNumber = this.currentAccount.accountNumber;
    const endDate = this.usageDate;
    const startDate = this.usageDate;
    await this.getData(accountNumber, startDate, endDate);
  },
  methods: {
    async getData(accountNumber, startDate, endDate) {
      try {
        this.loadState = undefined;
        this.$emit('daily-usage-read', 'loading');
        this.hourlyUsageData = await this.getHourlyData(accountNumber, ToServerDateTruncate(startDate), ToServerDateTruncate(startDate));
        if (!this.hourlyUsageData || !this.hourlyUsageData.length) {
          return;
        }
        this.maxDailyUsageValue = this.findMaxUsageValue(this.hourlyUsageData);

        this.loadState = 'complete';
        this.$emit('daily-usage-read', 'complete');
      } catch (err) {
        this.loadState = 'error';
        DumpError("Error getting hourly usage data.", err);
      }
      

    },
    async getHourlyData(accountNumber, startDate, endDate) {
      const parsedItemDateUTCStart = parseISO(startDate);
      const parsedItemDateUTCEnd = parseISO(endDate);

      const response = await GMPAPI.GetPeriodUsage(accountNumber, 'hourly', ToServerDateTruncate(parsedItemDateUTCStart), ToServerDateTruncate(parsedItemDateUTCEnd));
      if (!response || !response.intervals || !response.intervals.length || !response.intervals[0].values || !response.intervals[0].values.length) {
        this.loadState = 'empty';
        return [];
      }

      const hourlyUsageData = response.intervals[0].values;
      const hourlyUsageWithWeatherIcon = hourlyUsageData.map(item => {
        if (item && (item.temperature || item.darkskyTemperature)) {
          return { ...item, weatherIconImageUrl: this.getWeatherIcon(item.weatherIcon, this.isNight(item.date)) };
        }
        return { ...item, weatherIconImageUrl: "" };
      });
      return hourlyUsageWithWeatherIcon;
    },
    getOnPeakUsage(usage) {
      return usage.onPeak ? usage.onPeak : 0;
    },
    getOffPeakUsage(usage) {
      return usage.offPeak ? usage.offPeak : 0;
    },
    getGenerationToHome(usage) {
      return (usage.generation - usage.returnedGeneration); 

      //use this if we want to limit the 'generation to home' to be always positive.
      //return Math.max(0,(usage.generation - usage.returnedGeneration)); 
    },
    showPeakEventFlag(usage) {
      //TODO: This is a temporary fix to show the notice for the peak event based on detecting odd data. This should be removed once the backend sends the correct data.
      if ((usage.generation - usage.returnedGeneration) < 0) {
        return true;
      }
      if (usage.consumedTotal > usage.totalEnergyUsed) {
        return true;
      }
    },
    isNight(date){
      const hour = getHours(date);
      //get the month
      const month = date.getMonth();
      //get the start and end times for the month
      const [sunrise, sunset] = this.VermontSunriseSusetHours[month];
      //if the hour is less than sunrise or greater than sunset, it is night
      return hour < sunrise || hour > sunset;
    },
    convertToTimeRange(date) {
      const militaryHour = getHours(date);
      const hourStart = militaryHour;
      const hourEnd = (militaryHour + 1) % 24;

      const startDate = new Date();
      startDate.setHours(hourStart, 0, 0, 0);

      const endDate = new Date();
      endDate.setHours(hourEnd, 0, 0, 0);

      const timeRange = format(startDate, 'haaa') + '-' + format(endDate, 'haaa');
      return timeRange;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>