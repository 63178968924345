import { forEach } from '../functions';

const mobileMenuSubMenuExpandedClass = 'gmp-sub-menu-mobile--expanded';
const subMenuExpandedClass = 'gmp-sub-menu-item--expanded';

export default function menu(){
  var nav = document.getElementById('site-navigation');
  var menuItems = nav.querySelectorAll('.menu-item-has-children');
  var menu = document.getElementById('primary-menu');
  var subMenus = document.querySelectorAll('.sub-menu');
  var menuClose = document.getElementById('close-mobile');
  jQuery(menuItems).append('<div class="gmp-sub-menu-button-container"><button class="gmp-sub-menu-mobile-back gds-button gds-button-outline gds-button-circle"><svg class="gds-icon"><use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-left" /></svg></button></div>');
  forEach(menuItems, function(index, item) {
    var link = item.querySelector('a');

    link.addEventListener('click', function(event) {
      event.preventDefault();
      forEach(menuItems, function(index, m){
        if(m !== item)
          m.classList.remove(subMenuExpandedClass)
      })
      nav.classList.add(mobileMenuSubMenuExpandedClass);
      item.classList.add(subMenuExpandedClass);
    });
    jQuery(document).on('click', '.gmp-sub-menu-mobile-back', (event) => closeSubMenu(menuItems, nav));
  });
  menuClose.addEventListener('click', (event) => closeSubMenu(menuItems, nav, event));
}

function closeSubMenu(menuItems, nav){
  forEach(menuItems, function(index, m) {
    m.classList.remove(subMenuExpandedClass)
  })
  nav.classList.remove(mobileMenuSubMenuExpandedClass)
}
