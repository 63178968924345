<template>
  <div class="usage-detail__component-container">
    <div class="usage-detail__usage-performance">
      <section class="gds-space-stack-l">
          <UsageDetailControls
          selectedDateWidth="300px"
          datePickerDateType="month"
          :showGoToTodayButton="true"
          :selectedDate="selectedDate"
          :datePickerDisabledDates="disabledDates"
          :selectedDateSubText="selectedDateSubText"
          :showJumpToDateButton="showJumpToDateButton"
          :showDateForwardsButton="showDateForwardsButton"
          :showDateBackwardsButton="showDateBackwardsButton"
          :onGoToTodayClicked="onGoToTodayClicked"
          :onDateForwardsClicked="onDateForwardsClicked"
          :onSelectedDateChanged="onSelectedDateChanged"
          :onDateBackwardsClicked="onDateBackwardsClicked"
          :disableAllDateNavControls="!loadState"
          :disableDateNavigationButtonBack="disableDateNavigationButtonBack"
          :disableDateNavigationButtonForward="disableDateNavigationButtonForward"/>
      </section>
      <div v-if="!loadState" class="table-loading" />
      <div class="usage-page--interior-status-container">
        <flow-error v-if="loadState === 'error'" name="Usage information" state="error" />
        <flow-error v-if="loadState === 'maintenance'" name="Usage information" state="maintenance" />
        <flow-error v-if="loadState === 'unavailable'" name="Usage information" state="unavailable"
          img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
        <flow-error v-if="loadState === 'empty'" name="Usage information" state="nodata"
          img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
      </div>
      <div v-if="loadState === 'complete'">
        <section class="gds-space-stack-l">
          <div class="my-account__usage-graph-wrapper">
            <!-- START widget-net-metering -->
            <div class="usage-detail__usage-performance__widget-net-metering-wrapper">
              <widget-net-metering
              loadState="complete"
              :billingSummary="billingSummary"
              :summaryGeneration="summaryGeneration"
              :summaryTextGeneration="summaryTextGenerationForNetMeteringWidget"
              :summaryIconTotalGeneration="summaryIconTotalConsumptionForNetMeteringWidget" />
            </div>
            <!-- START chart total summary - generation -->
            <section class="usage-detail__section-heading gds-space-stack-l">
              <div
                id="usage-detail__usage-performance__total-summary__generation"
                class="usage-detail__usage-performance__total-summary">
                <div class="usage-detail__usage-performance__total-summary__top-row">
                  Total Generation
                </div>
                <div class="usage-detail__usage-performance__total-summary__bottom-row">
                  <div class="usage-detail__usage-performance__total-summary__bottom-row__left">
                    <!-- START summary detail and circle -->
                    <div class="usage-detail__usage-performance__total-summary__bottom-row__left__detail-item">
                      <div class="usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--circle-container">
                        <img class="usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--circle"
                        :src="'/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/' + summaryIconTotalGeneration"
                      alt="performance indicator" />
                      </div>
                        <div
                        v-html="summaryTextGeneration"
                        class="usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--text">
                        </div>
                    </div>
                    <!-- END summary detail and circle -->
                  </div>
                  <div
                  v-if="!isMobbileView && !isTabletView"
                  class="usage-detail__usage-performance__total-summary__bottom-row__right">
                    <!-- START chart-legend-wrapper - generation -->
                    <div class="usage-detail__performance__total-summary__compare-legend-container">
                      <div class="usage-detail__performance__total-summary__legend-item gds-space-inline-ml">
                        <div class="usage-detail__performance__total-summary__legend-item--circle current generation"></div>
                        <div class="usage-dashboard__legend-text-container">
                          <div class="usage-detail__performance__total-summary__legend-item--text">Total Generation</div>
                          <div class="usage-detail__performance__total-summary__legend-item--text legend-item-sub-text">{{legendCurrentConsumptionValue}}</div>
                        </div>
                      </div>
                      <div class="usage-detail__performance__total-summary__legend-item">
                        <div class="usage-detail__performance__total-summary__legend-item--circle previous"></div>
                        <div class="usage-dashboard__legend-text-container">
                          <div class="usage-detail__performance__total-summary__legend-item--text">Previous Total Generation</div>
                          <div class="usage-detail__performance__total-summary__legend-item--text legend-item-sub-text">{{legendPreviousConsumptionValue}}</div>
                        </div>
                      </div>
                    </div>
                    <!-- END chart-legend-wrapper - generation -->
                  </div>
                </div>
              </div>
            </section>
            <!-- END chart total summary - generation -->
            <!-- END widget-net-metering -->
            <!-- START chart - generation -->
            <div class="usage-detail__usage-performance__current-usage-chart-ref-wrapper" >
              <div ref="currentUsageChartRefGeneration" class="current-usage-chart-ref"/>
            </div>
            <!-- END chart - generation -->
            <!-- START mobile chart-legend-wrapper generation -->
            <section v-if="isMobbileView || isTabletView" class="gds-space-stack-l">
              <div class="usage-detail__usage-performance__chart-legend-wrapper net-metering">
                <div class="usage-detail__usage-performance__chart-legend-items">
                  <div class="usage-detail__usage-performance__chart-legend-item">
                    <span class="circle current generation"></span>
                    <div class="usage-detail__usage-performance__chart-legend-item__label-and-value">
                      <div class="usage-detail__usage-performance__chart-legend-item__label">
                        Total Generation
                      </div>
                      <div class="usage-detail__usage-performance__chart-legend-item__value">
                        {{legendCurrentConsumptionValue}}
                      </div>
                    </div>
                  </div>
                  <div class="usage-detail__usage-performance__chart-legend-item">
                    <span class="circle previous"></span>
                    <div class="usage-detail__usage-performance__chart-legend-item__label-and-value">
                      <div class="usage-detail__usage-performance__chart-legend-item__label">
                        Previous Total Generation
                      </div>
                      <div class="usage-detail__usage-performance__chart-legend-item__value">
                        {{legendPreviousConsumptionValue}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- END mobile chart-legend-wrapper generation -->
            <!-- START chart total summary - generation -->
            <section class="usage-detail__section-heading gds-space-stack-l">
              <div
                id="usage-detail__usage-performance__total-summary__consumption"
                class="usage-detail__usage-performance__total-summary">
                <div class="usage-detail__usage-performance__total-summary__top-row">
                  Total Consumption
                </div>
                <div class="usage-detail__usage-performance__total-summary__bottom-row">
                  <div class="usage-detail__usage-performance__total-summary__bottom-row__left">
                    <!-- START summary detail and circle -->
                    <div class="usage-detail__usage-performance__total-summary__bottom-row__left__detail-item">
                      <div class="usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--circle-container">
                        <img class="usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--circle"
                        :src="'/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/' + summaryIconTotalConsumption"
                      alt="performance indicator" />
                      </div>
                        <div
                        v-html="summaryTextConsumption"
                        class="usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--text">
                        </div>
                    </div>
                    <!-- END summary detail and circle -->
                  </div>
                  <div
                  v-if="!isMobbileView && !isTabletView"
                  class="usage-detail__usage-performance__total-summary__bottom-row__right">
                    <!-- START chart-legend-wrapper - generation -->
                    <div class="usage-detail__performance__total-summary__compare-legend-container">
                      <div class="usage-detail__performance__total-summary__legend-item gds-space-inline-ml">
                        <div class="usage-detail__performance__total-summary__legend-item--circle current consumption"></div>
                        <div class="usage-dashboard__legend-text-container">
                          <div class="usage-detail__performance__total-summary__legend-item--text">Total Consumption</div>
                          <div class="usage-detail__performance__total-summary__legend-item--text legend-item-sub-text">{{legendCurrentConsumptionValue}}</div>
                        </div>
                      </div>
                      <div class="usage-detail__performance__total-summary__legend-item">
                        <div class="usage-detail__performance__total-summary__legend-item--circle previous"></div>
                        <div class="usage-dashboard__legend-text-container">
                          <div class="usage-detail__performance__total-summary__legend-item--text">Previous Total Consumption</div>
                          <div class="usage-detail__performance__total-summary__legend-item--text legend-item-sub-text">{{legendPreviousConsumptionValue}}</div>
                        </div>
                      </div>
                    </div>
                    <!-- END chart-legend-wrapper - generation -->
                  </div>
                </div>
              </div>
            </section>
            <!-- END chart total summary - generation -->
            <!-- START chart - consumption -->
            <div class="usage-detail__usage-performance__current-usage-chart-ref-wrapper" >
              <div ref="currentUsageChartRefTotalHomeConsumption" class="current-usage-chart-ref"/>
            </div>
            <!-- END chart - consumption -->
            <!-- START mobile chart-legend-wrapper consumption -->
            <section v-if="isMobbileView || isTabletView" class="gds-space-stack-l">
              <div class="usage-detail__usage-performance__chart-legend-wrapper  net-metering">
                <div class="usage-detail__usage-performance__chart-legend-items">
                  <div class="usage-detail__usage-performance__chart-legend-item">
                    <span class="circle current consumption"></span>
                    <div class="usage-detail__usage-performance__chart-legend-item__label-and-value">
                      <div class="usage-detail__usage-performance__chart-legend-item__label">
                        Total Home Consumption
                      </div>
                      <div class="usage-detail__usage-performance__chart-legend-item__value">
                        {{legendCurrentConsumptionValue}}
                      </div>
                    </div>
                  </div>
                  <div class="usage-detail__usage-performance__chart-legend-item">
                    <span class="circle previous"></span>
                    <div class="usage-detail__usage-performance__chart-legend-item__label-and-value">
                      <div class="usage-detail__usage-performance__chart-legend-item__label">
                        Previous Total Home Consumption
                      </div>
                      <div class="usage-detail__usage-performance__chart-legend-item__value">
                        {{legendPreviousConsumptionValue}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- END mobile chart-legend-wrapper consumption -->
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { format, parseISO, isBefore, isAfter, isToday, isEqual, subDays, isWithinInterval } from "date-fns";
import GMPAPI from "../../../../services/gmpapi";
import { DrawChart } from "./usagedetailperformance";
import MediaQueryMixin from "../../../mixins/MediaQueryMixin";
import UsageDetailBaseComponentMixin from "../../../mixins/UsageDetailBaseComponentMixin";
import { DumpError, ToServerDateTruncate } from "../../../../utilities";
import UsageDetailControls from "../usagedetailcontrols/UsageDetailControls";
import { GetComponentStatus } from '../../../../services/statuspage';
import WidgetNetMetering from "../widgetnetmetering/WidgetNetMetering.vue";

const CUR_BILL_PER_GEN_LESS = 'currBillPerGeneratedLess';
const CUR_BILL_PER_GEN_MORE = 'currBillPerGeneratedMore';
const PAST_BILL_PER_GEN_LESS = 'pastBillPerGeneratedLess';
const PAST_BILL_PER_GEN_MORE = 'pastBillPerGeneratedMore';

const CUR_BILL_PER_CONS_LESS = 'currBillPeConsumedLess';
const CUR_BILL_PER_CONS_MORE = 'currBillPerConsumedMore';
const PAST_BILL_PER_CONS_LESS = 'pastBillPerConsumedLess';
const PAST_BILL_PER_CONS_MORE = 'pastBillPerConsumedMore';

export default {
  name:'PerformanceChart',
  components: {
    WidgetNetMetering,
    UsageDetailControls
  },
  mixins: [
    MediaQueryMixin,
    UsageDetailBaseComponentMixin
  ],
  async mounted() {
    this.disableAllDateNavControls = true;
    try {
       // Before we do anything else, check to see if component is operational
       const status = await GetComponentStatus("Usage");
      if (status !== "operational" && !(this.isAdmin)) {
        this.loadState = status;
        this.disableAllDateNavControls = true;
        return;
      }

      this.billingPeriods = await this.getBillingPeriods(this.currentAccount.accountNumber);

      if (!this.billingPeriods || !this.billingPeriods.periods ||  this.billingPeriods.periods.length === 0) {
        this.loadState = 'empty';
        return;
      } 

      this.disabledDates = this.disableDatePickerDatesOutsideBillingPeriods(this.billingPeriods.periods);
      
      this.getBillingCycleDates(this.billingPeriods);
      this.billingCycleDates = this.billingPeriods.periods;

      this.updateDateBackForwardButtons();
      const today = new Date();
      if (!this.previousBillingCycleDates || !this.previousBillingCycleDates.startDate) {
        this.loadState = 'unavailable';
        return;
      }
      this.checkDeviceWidthAndRefreshChart(parseISO(this.previousBillingCycleDates.startDate), today);
      this.updateLegendPreviousConsumption();
    
    } catch (err) {
      DumpError("Usage detail performance refresh error", err);
      if (err.response && err.response.status === 404) {
        this.loadState = 'unavailable';
      } else {
        this.loadState = "error";
      }
      return;
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    isMobbileView() {
      return this.deviceIsSmall;
    },
    isTabletView() {
      return this.deviceIsMedium
      || this.deviceIsLarge;
    },
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    }
  },
  watch: {
    isMobbileView() {
      const dates = this.getCurrentStartAndEndDates();
      this.checkDeviceWidthAndRefreshChart(dates.startDate, dates.endDate);
    },
    isTabletView() {
      const dates = this.getCurrentStartAndEndDates();
      this.checkDeviceWidthAndRefreshChart(dates.startDate, dates.endDate);
    },
    isDesktopView() {
      const dates = this.getCurrentStartAndEndDates();
      this.checkDeviceWidthAndRefreshChart(dates.startDate, dates.endDate);
    }
  },
  data() {
    return {
      summaryIconTotalGeneration: '',
      summaryGeneration: ``,
      summaryTextGeneration: ``,
      summaryIconTotalConsumption: '',
      summaryTextGenerationForNetMeteringWidget: ``,
      summaryIconTotalConsumptionForNetMeteringWidget: '',
      summaryTextConsumption: ``,
      billingSummary: undefined,
      billingSummaryPrevious: undefined,
      summaryText: '',
      selectedDate: '',
      keyNameBack: 'back',
      selectedDateSubText: '',
      legendCurrentConsumptionValue: '',
      legendPreviousConsumptionValue: '',
      showJumpToDateButton: true,
      showDateForwardsButton: true,
      showDateBackwardsButton: true,
      dailyPeriodUsageEndDate: new Date(),
      chartXAxisSteps: 2,
      loadState: undefined,
      allBillingCycleDates: undefined,
      currentBillingCycleDates: undefined,
      previousBillingCycleDates: undefined,
      disableDateNavigationButtonBack: false,
      disableDateNavigationButtonForward: false,
      differenceInUsage: { difference: undefined, language: undefined }
    };
  },
  methods: {
    async getBillDetails(accountNumber, billIdentifier) {
      return await GMPAPI.getBillDetails(accountNumber, billIdentifier);
    },
    strToNum(year, month, day) {
      const timestamp = year * 10000 + month * 100 + day;
      return timestamp;
    },
    onDateBackwardsClicked() {
      this.changeBillingCycleDates(this.keyNameBack);
    },
    onDateForwardsClicked() {
      this.changeBillingCycleDates('forward');
    },
    checkDeviceWidthAndRefreshChart(startDate, endDate) {
      this.setChartXAxisSteps();
      this.RefreshChart(startDate, endDate);
    },
    getCurrentStartAndEndDates() {
      let endDate = this.currentBillingCycleDates.current ? new Date().toISOString() : this.currentBillingCycleDates.endDate;

      return {
        endDate: parseISO(endDate),
        startDate: parseISO(this.previousBillingCycleDates.startDate)
      }
    },
    setChartXAxisSteps() {
      if (this.isMobbileView) {
        this.chartXAxisSteps = 9;
      } else if (this.isTabletView) {
        this.chartXAxisSteps = 5;
      } else if (this.isDesktopView) {
        this.chartXAxisSteps = 2;
      }
    },
    onGoToTodayClicked() {
      this.onSelectedDateChanged(new Date());
    },
    getXAxisLabels(usageData, previousUsageData) {
      const xAxisLabels = [];

      usageData.forEach((usage, index) => {
        let labelString = `${format(usage.date, 'M/d')}`;

        if (index < previousUsageData.length) {
            labelString += `<br>Day ${index + 1}`;
        }

        xAxisLabels.push(labelString);
      });

      return xAxisLabels;
    },
    getCumulativeUsageDataSum(usageData, dataPoint) {
      let cumulativeSum = 0;
      let cumulativeArray = [];

      usageData.forEach((data) => {
        cumulativeSum += parseFloat(data[dataPoint]);
        cumulativeArray.push(cumulativeSum);
      });

      return cumulativeArray;
    },
    onSelectedDateChanged(selectedDate) {
      const newBillingCycleDates = this.getMatchingCurrentBillingCycle(this.allBillingCycleDates.periods, selectedDate);
      
      if (newBillingCycleDates) {
        this.previousBillingCycleDates = newBillingCycleDates.previousBillingCycleDates || newBillingCycleDates.currentBillingCycleDates;
        this.currentBillingCycleDates = newBillingCycleDates.currentBillingCycleDates;
        this.dailyPeriodUsageEndDate = parseISO(this.currentBillingCycleDates.endDate);
        
        const startDate = parseISO(this.previousBillingCycleDates.startDate || this.currentBillingCycleDates.startDate);
        const today = new Date();
        let endDate;
        if (isAfter(parseISO(this.currentBillingCycleDates.endDate), today,)) {
          endDate = today;
        } else {
          endDate =  parseISO(this.currentBillingCycleDates.endDate);
        }

        this.RefreshChart(startDate, endDate);
        this.updateDateBackForwardButtons();
        this.updateLegendPreviousConsumption();
      }
    },
    dateIsWithinRange(targetDate, startDate, endDate) {
      const start = parseISO(startDate);
      const end = parseISO(endDate);
      const target = parseISO(targetDate);

      return (isEqual(target, start) || (isAfter(target, start) && isBefore(target, end)));
    },
    parseDailyUsageData(dailyUsageData, startDate, endDate) {
      const dateFormat = 'yyyy-MM-dd';
      const startDateFormatted = format(startDate, dateFormat);
      const endDateFormatted = format(endDate, dateFormat);

      const filteredArr = dailyUsageData.intervals[0].values.filter(value => {
        const dateFormatted = format(value.date, dateFormat);
        return this.dateIsWithinRange(dateFormatted, startDateFormatted, endDateFormatted);
      });

      return filteredArr;
    },
    updateSelectedDateAndSummary(startDate, endDate) {
      const dateFormatMdy = 'MMMM dd, yyyy';
      this.selectedDate = format(endDate, 'MMMM yyyy');
      this.selectedDateSubText = `${format(startDate, dateFormatMdy)} - ${format(endDate, dateFormatMdy)}`;
      this.legendCurrentConsumptionValue = this.selectedDateSubText;
    },
    updateLegendPreviousConsumption() {
      const dateFormatMdy = 'MMMM dd, yyyy';
      if (this.previousBillingCycleDates) {
        this.legendPreviousConsumptionValue = `${format(parseISO(this.previousBillingCycleDates.startDate), dateFormatMdy)} - ${format(parseISO(this.previousBillingCycleDates.endDate), dateFormatMdy)}`;
      }
    },
    getTotalCycleUsage(chartData, dataPoint) {
      let totalUsage = 0;
      chartData.forEach((data) => totalUsage += data[dataPoint]);
      return parseFloat(totalUsage.toFixed(1));
    },
    getDifferenceInUsage(previousUsage, currentUsage) {
      if (currentUsage > previousUsage) {
        return {
          difference: Math.round(currentUsage - previousUsage),
          language: 'more'
        }
      } else {
        return {
          difference: Math.round(previousUsage - currentUsage),
          language: 'less'
        }
      }
    },
    updateDateBackForwardButtons() {
      if (this.previousBillingCycleDates) {
        this.disableDateNavigationButtonBack = this.previousBillingCycleDates.earliest ? true : false;
      } else {
        this.disableDateNavigationButtonBack = true;
      }

      if (this.currentBillingCycleDates) {
        this.disableDateNavigationButtonForward = this.currentBillingCycleDates.current ? true : false;
      } else {
        this.disableDateNavigationButtonForward = true;
      }
    },
    getBillingCycleDates(data) {
      this.allBillingCycleDates = JSON.parse(JSON.stringify(data));
      this.allBillingCycleDates.periods[this.allBillingCycleDates.periods.length - 1].earliest = true;

      this.allBillingCycleDates.periods.forEach(period => {
        if (period.current) { this.currentBillingCycleDates = period; }
        if (period.last) { this.previousBillingCycleDates = period; }
      });

      if (isToday(parseISO(this.currentBillingCycleDates.startDate))) {
        this.currentBillingCycleDates = this.previousBillingCycleDates;
        const searchDateForNewPrevious = subDays(parseISO(this.previousBillingCycleDates.startDate), 1);

        this.allBillingCycleDates.periods.forEach(period => {
          if (isWithinInterval(searchDateForNewPrevious, {
            start: parseISO(period.startDate),
            end: parseISO(period.endDate)
          })) {
            this.previousBillingCycleDates = period;
          }
        });
      }
    },

    changeBillingCycleDates(direction) {
      let target;

      if (direction === this.keyNameBack) {
        target = this.currentBillingCycleDates.startDate;
      } else {
        target = this.previousBillingCycleDates ? this.previousBillingCycleDates.startDate : this.currentBillingCycleDates.startDate;
      }

      const newData = this.updateBillingCycleDates(direction, this.allBillingCycleDates.periods, target);
      if (newData) {
        this.previousBillingCycleDates = newData.previousBillingCycleDates || newData.currentBillingCycleDates;
        this.currentBillingCycleDates = newData.currentBillingCycleDates;
        this.dailyPeriodUsageEndDate = parseISO(this.currentBillingCycleDates.endDate);

        const startDate = newData.previousBillingCycleDates ?
                            newData.previousBillingCycleDates.startDate : newData.currentBillingCycleDates.startDate;
        let endDate = parseISO(newData.currentBillingCycleDates.endDate);
        const today = new Date();
        if (isAfter(endDate, today)) {
          endDate = today;
        } 

        this.RefreshChart(parseISO(startDate), endDate);
        this.updateDateBackForwardButtons();
        this.updateSelectedDateAndSummary(parseISO(this.currentBillingCycleDates.startDate), endDate);
        this.updateLegendPreviousConsumption();
      }
    },
    updateBillingCycleDates(direction, billingCycleDates, currentStartDate) {
        const index = billingCycleDates.findIndex(obj => obj.startDate === currentStartDate);
        const indexChangeCurrent = (direction === this.keyNameBack) ? index + 1 : index - 2;
        const indexChangePrevious = (direction === this.keyNameBack) ? index + 2 : index - 1;

        if (index === -1 || index === 0) {
          return [];
        } else {
          return {
            currentBillingCycleDates: billingCycleDates[indexChangeCurrent],
            previousBillingCycleDates: billingCycleDates[indexChangePrevious]
          }
        }
    },
    getMatchingCurrentBillingCycle(allBillingCycleDates, searchDate) {
      let currentBillingCycleDates = null;
      let previousBillingCycleDates = null;

      allBillingCycleDates.forEach((cycle, index) => {
        if (isWithinInterval(searchDate, {
          start: parseISO(cycle.startDate), end: parseISO(cycle.endDate)
        })) {
          if (isToday(parseISO(cycle.startDate))) {
            currentBillingCycleDates = allBillingCycleDates[index + 1] || null;
            previousBillingCycleDates = allBillingCycleDates[index + 2] || null;
          } else {
            currentBillingCycleDates = cycle;
            previousBillingCycleDates = allBillingCycleDates[index + 1] || null;
          }
        }
      });

      if (currentBillingCycleDates) {
        return {
          currentBillingCycleDates,
          previousBillingCycleDates
        };
      }

      return undefined;
    },
    async getDailyPeriodUsageData(startDate, endDate) {
      this.disableAllDateNavControls = true;
      const dailyUsageData = await GMPAPI.GetPeriodUsage(this.currentAccount.accountNumber, 'daily', ToServerDateTruncate(startDate), ToServerDateTruncate(endDate));
      this.disableAllDateNavControls = false;

      if (dailyUsageData && dailyUsageData.intervals[0] && dailyUsageData.intervals[0].values.length > 0) {
          if (!this.hasConsumption(dailyUsageData)) {
            this.loadState = 'empty';
            return [];
          }
      }
      return dailyUsageData;
    },
    buildNetMeteringWidgetSummaryText(billingSummary) {
      const netGeneration = billingSummary.totalGrossGenerated - billingSummary.totalConsumption;
      const netGenerationAbs = Math.abs(netGeneration);
      const formattedNetGeneration = netGenerationAbs.toLocaleString('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
      const netGenerationIsNegative = netGeneration < 0;
      const isCurrentBillingPeriod = this.currentBillingCycleDates && this.currentBillingCycleDates.current;
      const cbpWording  = isCurrentBillingPeriod ? ' so far' : '';

      if (netGenerationIsNegative) {
        this.summaryGeneration = `${formattedNetGeneration} kWh Net Use`;
        this.summaryIconTotalConsumptionForNetMeteringWidget = 'usage-icon-performance-arrow-down.svg';
        this.summaryTextGenerationForNetMeteringWidget = `Generated ${formattedNetGeneration} kWh less than consumed${cbpWording} this billing period.`;
      } else {
        this.summaryGeneration = `${formattedNetGeneration} kWh Credit`;
        this.summaryIconTotalConsumptionForNetMeteringWidget = 'usage-icon-performance-plus.svg';
        this.summaryTextGenerationForNetMeteringWidget = `Generated ${formattedNetGeneration} kWh more than consumed${cbpWording} this billing period.`;
      }
    },
    buildUsageContext() {
      const cbcd = this.currentBillingCycleDates;
      const isCurrentBilingCycle = !!(cbcd && cbcd.current);
      const summary  = this.billingSummary;
      const summaryPrevious  = this.billingSummaryPrevious;

      const usageContext = {
        generation: {
          context: '',
          differenceKwh: 0
        },
        consumption: {
          context: '',
          differenceKwh: 0
        }
      };

      const consIsPositive = summary.totalConsumption > summaryPrevious.totalConsumption;
      const genIsPositive = summary.totalGrossGenerated > summaryPrevious.totalGrossGenerated;

      usageContext.consumption.differenceKwh = Math.abs((summary.totalConsumption - summaryPrevious.totalConsumption).toFixed(1)) ;

      usageContext.generation.differenceKwh = Math.abs((summary.totalGrossGenerated - summaryPrevious.totalGrossGenerated).toFixed(1));

      if (isCurrentBilingCycle) {
        if (genIsPositive) {
          usageContext.generation.context = CUR_BILL_PER_GEN_MORE;
        } else {
          usageContext.generation.context = CUR_BILL_PER_GEN_LESS;
        }

        if (consIsPositive) {
          usageContext.consumption.context = CUR_BILL_PER_CONS_MORE;
        } else {
          usageContext.consumption.context = CUR_BILL_PER_CONS_LESS;
        }
      } else {
        if (genIsPositive) {
          usageContext.generation.context = PAST_BILL_PER_GEN_MORE;
        } else {
          usageContext.generation.context = PAST_BILL_PER_GEN_LESS;
        }

        if (consIsPositive) {
          usageContext.consumption.context = PAST_BILL_PER_CONS_MORE;
        } else {
          usageContext.consumption.context = PAST_BILL_PER_CONS_LESS;
        }
      }

      this.setChartSummaryText(usageContext);
    },
    setChartSummaryText(usageContext) {
      const differenceKwhGeneration = usageContext.generation.differenceKwh;
      const differenceKwhConsumption = usageContext.consumption.differenceKwh;

      switch (usageContext.generation.context) {
        case CUR_BILL_PER_GEN_LESS:
          this.summaryIconTotalGeneration = 'usage-icon-performance-arrow-down.svg';
          this.summaryTextGeneration = `<strong>${differenceKwhGeneration} kWh less</strong> energy generated than previous billing period at this point.`;
          break;
        case CUR_BILL_PER_GEN_MORE:
          this.summaryIconTotalGeneration = 'usage-icon-performance-checkmark.svg';
          this.summaryTextGeneration = `<strong>${differenceKwhGeneration} kWh more</strong> energy generated than previous billing period at this point.`;
          break;
        case PAST_BILL_PER_GEN_LESS:
          this.summaryIconTotalGeneration = 'usage-icon-performance-arrow-down.svg';
          this.summaryTextGeneration = `<strong>${differenceKwhGeneration} kWh less</strong> energy generated than previous billing period.`;
          break;
        case PAST_BILL_PER_GEN_MORE:
          this.summaryIconTotalGeneration = 'usage-icon-performance-checkmark.svg';
          this.summaryTextGeneration = `<strong>${differenceKwhGeneration} kWh more</strong> energy generated than previous billing period.`;
          break;
      }

      switch (usageContext.consumption.context) {
        case CUR_BILL_PER_CONS_LESS:
          this.summaryIconTotalConsumption = 'usage-icon-performance-checkmark.svg';
          this.summaryTextConsumption = `<strong>${differenceKwhConsumption} kWh less</strong> energy consumed than previous billing period at this point.`;
          break;
        case CUR_BILL_PER_CONS_MORE:
          this.summaryIconTotalConsumption = 'usage-icon-performance-arrow-up.svg';
          this.summaryTextConsumption = `<strong>${differenceKwhConsumption} kWh more</strong> energy consumed than previous billing period at this point.`;
          break;
        case PAST_BILL_PER_CONS_LESS:
          this.summaryIconTotalConsumption = 'usage-icon-performance-checkmark.svg';
          this.summaryTextConsumption = `<strong>${differenceKwhConsumption} kWh </strong>less energy consumed than previous billing period.`;
          break;
        case PAST_BILL_PER_CONS_MORE:
          this.summaryIconTotalConsumption = 'usage-icon-performance-arrow-up.svg';
          this.summaryTextConsumption = `<strong>${differenceKwhConsumption} kWh</strong> more energy consumed than previous billing period.`;
          break;
      }
    },
    findBillingSummaryByBillId(responses, billId) {
      return responses.find(element => element.value.billId === billId);
    },
    async RefreshChart(startDate, endDate) {
      this.loadState = undefined;

      let dailyUsageData;
      try {
        dailyUsageData = await this.getDailyPeriodUsageData(startDate, endDate);
        if (!dailyUsageData || !dailyUsageData.intervals || dailyUsageData.intervals[0].values.length === 0) {
          this.loadState = 'empty';
          return;
        } 
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.errorCode === "MDM_NO_ACTIVE_SERVICES") {
          this.loadState = "unavailable";
        } else if (err && err.response && err.response.data && err.response.data.errorCode === "NO_DATA_FOUND" || err.response && err.response.status === 404) {
          this.loadState = 'empty';
        } else {
          this.handleRefreshError(err);
        }
        this.disableAllDateNavControls = false;
        return;
      }

      this.updateDateBackForwardButtons();

      let previousBillingCycleDailyUsageData;
      let currentBillingCycleDailyUsageData;
      let chartDataPreviousGeneration;
      let chartDataCurrentGeneration;
      const generationDataPoint = 'generation';

      if (!this.currentBillingCycleDates.billIdentifier) {
        const usageSummary = await GMPAPI.GetUsageSummary(this.currentAccount.accountNumber);

        this.currentBillingCycleDates.billingSummary = {
          totalGenerationSentToGrid: usageSummary.totalGenerationSentToGrid || 0,
          totalConsumption: usageSummary.totalConsumedFromGrid || 0,
          totalGeneration: usageSummary.totalGrossGenerated || 0,
          totalGenerationUsedByHome: usageSummary.totalGenerationUsedByHome || 0,
          totalEnergyUsed: usageSummary.totalConsumption || 0
        };
      }

      const billingSummaryRequests = [];
      const currentAccountNumber = this.currentAccount.accountNumber;
      const currentBillIdentifier = this.currentBillingCycleDates.billIdentifier;
      const previousBillIdentifier = this.previousBillingCycleDates.billIdentifier;
      const currentBillingCycleSummaryNeeded = !this.currentBillingCycleDates.billingSummary;
      const previousBillingCycleSummaryNeeded = !this.previousBillingCycleDates.billingSummary;

      let billDetailsRequestCurrent;
      let billDetailsRequestPrevious;

      if (previousBillingCycleSummaryNeeded) {
        billDetailsRequestPrevious = this.getBillDetails(currentAccountNumber, previousBillIdentifier)
        billingSummaryRequests.push(billDetailsRequestPrevious);
      }

      if (currentBillingCycleSummaryNeeded) {
        billDetailsRequestCurrent = this.getBillDetails(currentAccountNumber, currentBillIdentifier);
        billingSummaryRequests.push(billDetailsRequestCurrent);
      }

      if (billingSummaryRequests.length) {
        const billingSummaryRequestsResponse = await Promise.allSettled(billingSummaryRequests);

        const failedResponses = billingSummaryRequestsResponse
            .filter(result => result.status === 'rejected')
            .map(result => result.reason);

        if (failedResponses.length) {
          this.loadState = 'unavailable';
          return;
        }

        if (previousBillingCycleSummaryNeeded) {
          const matchingPreviousBillingSummary = this.findBillingSummaryByBillId(billingSummaryRequestsResponse, previousBillIdentifier);

          if (matchingPreviousBillingSummary && matchingPreviousBillingSummary.value) {
            this.previousBillingCycleDates.billingSummary = matchingPreviousBillingSummary.value;
          }
        }

        if (currentBillingCycleSummaryNeeded) {
          const matchingCurrentBillingSummary = this.findBillingSummaryByBillId(billingSummaryRequestsResponse, currentBillIdentifier);

          if (matchingCurrentBillingSummary && matchingCurrentBillingSummary.value) {
            this.currentBillingCycleDates.billingSummary = matchingCurrentBillingSummary.value;
          }
        }
      }

      this.loadState = 'complete';

      if (this.previousBillingCycleDates) {
        previousBillingCycleDailyUsageData = this.parseDailyUsageData(dailyUsageData, parseISO(this.previousBillingCycleDates.startDate), parseISO(this.previousBillingCycleDates.endDate));
        chartDataPreviousGeneration = this.getCumulativeUsageDataSum(previousBillingCycleDailyUsageData, generationDataPoint);
      }

      if (this.currentBillingCycleDates) {
        currentBillingCycleDailyUsageData = this.parseDailyUsageData(dailyUsageData, parseISO(this.currentBillingCycleDates.startDate), parseISO(this.currentBillingCycleDates.endDate));
        chartDataCurrentGeneration = this.getCumulativeUsageDataSum(currentBillingCycleDailyUsageData, generationDataPoint);
      }

      let chartDataPreviousTotalHouseConsumption;
      let chartDataCurrentTotalHouseConsumption;
      const totalEnergyUsedDataPoint = 'totalEnergyUsed';

      if (this.previousBillingCycleDates) {
        chartDataPreviousTotalHouseConsumption = this.getCumulativeUsageDataSum(previousBillingCycleDailyUsageData, totalEnergyUsedDataPoint);
      }

      if (this.currentBillingCycleDates) {
        chartDataCurrentTotalHouseConsumption = this.getCumulativeUsageDataSum(currentBillingCycleDailyUsageData, totalEnergyUsedDataPoint);
      }

      const totalPreviousUsage = this.getTotalCycleUsage(previousBillingCycleDailyUsageData, generationDataPoint);
      const totalCurrentUsage = this.getTotalCycleUsage(currentBillingCycleDailyUsageData, generationDataPoint);

      this.differenceInUsage = this.getDifferenceInUsage(totalPreviousUsage, totalCurrentUsage);

      const summary = this.currentBillingCycleDates.billingSummary;
      const summaryPrevious = this.previousBillingCycleDates.billingSummary;

      if (!summary || !summaryPrevious) {
        return;
      }

      this.billingSummary = {
        totalGenerationSentToGrid: summary.totalGenerationSentToGrid || 0,
        totalConsumedFromGrid: summary.totalConsumption || 0,
        totalGrossGenerated: summary.totalGeneration || 0,
        totalGenerationUsedByHome: summary.totalGenerationUsedByHome || 0,
        totalConsumption: summary.totalEnergyUsed || 0
      };

      this.billingSummaryPrevious = {
        totalGenerationSentToGrid: summaryPrevious.totalGenerationSentToGrid || 0,
        totalConsumedFromGrid: summaryPrevious.totalConsumption || 0,
        totalGrossGenerated: summaryPrevious.totalGeneration || 0,
        totalGenerationUsedByHome: summaryPrevious.totalGenerationUsedByHome || 0,
        totalConsumption: summaryPrevious.totalEnergyUsed || 0
      };

      if (this.currentBillingCycleDates && this.currentBillingCycleDates.current) {
        const lastDayIndexCurrent = currentBillingCycleDailyUsageData.length - 1;

        this.billingSummary.totalGrossGenerated = chartDataCurrentGeneration[lastDayIndexCurrent];
        this.billingSummary.totalConsumption = chartDataCurrentTotalHouseConsumption[lastDayIndexCurrent];

        this.billingSummaryPrevious.totalGrossGenerated = chartDataPreviousGeneration[lastDayIndexCurrent];
        this.billingSummaryPrevious.totalConsumption = chartDataPreviousTotalHouseConsumption[lastDayIndexCurrent];
      }

      this.buildUsageContext();
      this.buildNetMeteringWidgetSummaryText(this.billingSummary);

      const xAxisLabels = this.getXAxisLabels(currentBillingCycleDailyUsageData, previousBillingCycleDailyUsageData);

      this.updateSelectedDateAndSummary(parseISO(this.currentBillingCycleDates.startDate), endDate);

      await this.$nextTick();

      DrawChart(this.$refs.currentUsageChartRefGeneration, chartDataPreviousGeneration, chartDataCurrentGeneration, previousBillingCycleDailyUsageData, currentBillingCycleDailyUsageData, xAxisLabels, this.chartXAxisSteps, '#00704a');

      DrawChart(this.$refs.currentUsageChartRefTotalHomeConsumption, chartDataPreviousTotalHouseConsumption, chartDataCurrentTotalHouseConsumption, previousBillingCycleDailyUsageData, currentBillingCycleDailyUsageData, xAxisLabels, this.chartXAxisSteps, '#007cbc');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>