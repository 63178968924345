<template>
  <div>
    <usage-detail-controls
      class="usage-detail__usage-graph-nav-controls usage-detail__component-container"
      :selectedDate="intervalString"
      :datePickerDefaultDate="datePickerDefaultDate"
      :datePickerDisabledDates="disabledDates"
      :selectedDateWidth="selectedDateWidth"
      :datePickerDateType="datePickerDateType"
      :selectConfigMonthlyDailyHourly="selectConfigMonthlyDailyHourly" :onOptionSelectedMonthlyDailyHourly="selectOptionMonthlyDailyHourly"
      :showDateBackwardsButton="true" :onDateBackwardsClicked="dateBackwards"
      :showDateForwardsButton="true" :onDateForwardsClicked="dateForwards" :disableDateNavigationButtonForward="disableDateNavigationButtonForward" 
      :showJumpToDateButton="true" :onSelectedDateChanged="selectDate"
      :showGoToTodayButton="true" :onGoToTodayClicked="goToToday"
      :showViewOptionsButton="true" :onViewOptionsClicked="viewOptions"
      :viewOptionsLabel="'Graph Layers'"
      :disableDateNavigationButtonBack="disableDateNavigationButtonBack"
      :disableAllDateNavControls="disableAllDateNavControls" />
    <div v-if="!loadState" class="table-loading" />
    <div class="usage-page--interior-status-container">
      <flow-error v-if="loadState === 'error'" name="Usage information" state="error" />
      <flow-error v-if="loadState === 'maintenance'" name="Usage information" state="maintenance" />
      <flow-error v-if="loadState === 'unavailable'" name="Usage information" state="unavailable"
        img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
      <flow-error v-if="loadState === 'empty'" name="Usage information" state="nodata"
        img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
    </div>
    <div v-if="loadState === 'complete'" :class="{ 'dimmed': updatingChart }" class="usage-detail__usage-graph-container usage-detail__component-container">
      <div v-if="updatingChart" class="table-loading"></div>
      <section>
        <usage-chart ref="chartTop" @click="ClickTop" @IntervalAdjust="IntervalAdjust" :usageData="binsTop" :layers="visibleLayerNames" height="300px" />
        <usage-chart v-if="drillDepth === 'hourly' || drillDepth === 'daily'" ref="chartMiddle" @click="ClickMiddle"
          :usageData="binsMiddle" :mini="true" :selected="selectedMiddle"
          :layers="visibleLayerNames" height="150px" />
        <usage-chart v-if="drillDepth === 'hourly'" ref="chartBottom" :usageData="binsBottom" @click="ClickBottom"
          :mini="true" :selected="selectedBottom" :layers="visibleLayerNames"
          height="150px" />
      </section>
      <section class="usage-detail__usage-graph-key">
        <div class="usage-detail__usage-graph-help-text">To drill down into more detailed
          information, double click on the vertical bars of any month or day.</div>
        <div class="usage-detail__usage-graph-key--list">
          <div v-for="layer of visibleLayers" :key="layer.label" class="usage-detail__usage-graph-key--list-item">
            <div class="gds-flex-container gds-flex-container--left">
              <div class="usage-detail__usage-graph-key--color-box" :style="{ 'background-color': layer.flatColor }">
              </div>
              <div class="gds-font-size-s">{{ layer.label }}</div>
            </div>
          </div>
        </div>
      </section>
      <ModalGraphLayers v-if="showMenu" @update-selected="updateSelected" :layers="layers" :closeModal="closeLayersMenu" :isDashboard="false"/>
    </div>
  </div>
</template>

<script>
import UsageChart from "./UsageChart.vue";
import { GetComponentStatus } from '../../../../services/statuspage';
import { GetAllRates } from './usagedata';
import { subMonths, lastDayOfMonth, addMonths, addDays, addSeconds, format, startOfMonth, endOfMonth, startOfDay, endOfDay, startOfYear, endOfYear, startOfYesterday, startOfToday, parseISO, isFuture, subYears, isSameYear, isSameMonth, isSameDay } from 'date-fns';
import UsageDetailControls from "../usagedetailcontrols/UsageDetailControls.vue";
import UsageDetailBaseComponentMixin from "../../../mixins/UsageDetailBaseComponentMixin";
import { DumpError } from "../../../../utilities";
import ModalGraphLayers from "./ModalGraphLayers.vue";

const USAGE_YEARLY_RANGE_IN_YEARS = 5;

export default {
  name: "UsageDetailGraph",
  mixins: [
    UsageDetailBaseComponentMixin
  ],
  components: {
    UsageChart,
    UsageDetailControls,
    ModalGraphLayers
},
  data() {
    return {
      selectedMontlyIndex: undefined,
      datePickerDefaultDate: new Date(),
      updatingChart: false,
      dateRange13months: false,
      selectedIndexFor13months: 12,
      selectedDateWidth: '75px',
      selectedDateWidthDefault: '75px',
      selectedDateWidth13Months: '250px',
      selectedDateWidth: '75px',
      drillDepth: "monthly",
      usageYear: new Date().getFullYear(),
      layers: undefined,
      binsTop: undefined,
      binsMiddle: undefined,
      binsBottom: undefined,
      selectedMiddle: undefined,
      selectedBottom: undefined,
      showMenu: false,
      intervalDate: undefined,
      drillDropInterval: "monthly",
      drillDropIntervalSelections: [],
      datePickerDateType: "year",
      queryStartDate: undefined,
      queryResolution: undefined,
      queryLayers: undefined,
      loadState: undefined,
      disableDateNavigationButtonForward: undefined,
      disableDateNavigationButtonBack: false,
      selectConfigMonthlyDailyHourly: {
        width: '190px',
        buttonAppearance: true,
        options: [
          {
            label: 'Monthly',
            value: 'monthly'
          },
          {
            label: '13 Months',
            value: '13months'
          },
          {
            label: 'Yearly',
            value: 'yearly'
          },
          {
            label: 'Daily',
            value: 'daily'
          },
          {
            label: 'Hourly',
            value: 'hourly'
          }
        ]
      }
    };
  },
  watch: {
    async currentAccount() {
      await this.RefreshData();
    },
    intervalDate(newDate) {
      switch (this.drillDepth) {
        case 'monthly':
          this.disableDateNavigationButtonForward = isSameYear(newDate, new Date());
          break;
        case '13months':
          const futureDate = addMonths(newDate, 12);
          const lastDay = lastDayOfMonth(futureDate);

          this.disableDateNavigationButtonForward = isSameMonth(startOfDay(lastDay), new Date());
          break;
        case 'yearly':
          this.disableDateNavigationButtonForward = isSameYear(endOfYear(parseISO(`${this.usageYear}-01-01`)), new Date());
          break;
        case 'daily':
          this.disableDateNavigationButtonForward = isSameMonth(newDate, new Date());
          break;
        case 'hourly':
          this.disableDateNavigationButtonForward = isSameDay(newDate, new Date());
          break;
        default:
          this.disableDateNavigationButtonForward = true;
          break;
      }
    },
    drillDepth() {
      let selectedLabelAndValue;
      const $selectOption = document.querySelector('.usage-detail__select__option');

      switch (this.drillDepth) {
        case 'monthly':
          selectedLabelAndValue = 'Yearly';
          this.datePickerDateType = 'year';
          this.selectedDateWidth = this.selectedDateWidthDefault;
          $selectOption ? $selectOption.value = 'monthly' : null;
          break;
        case 'yearly':
          selectedLabelAndValue = 'Yearly';
          this.datePickerDateType = 'year';
          this.selectedDateWidth = this.selectedDateWidthDefault;
          $selectOption ? $selectOption.value = 'yearly' : null;
          break;
        case '13months':
          selectedLabelAndValue = '13 Months';
          this.datePickerDateType = 'month';
          this.selectedDateWidth = this.selectedDateWidth13Months;
          $selectOption ? $selectOption.value = '13months' : null;
          break;
        case 'daily':
          selectedLabelAndValue = 'Daily';
          this.datePickerDateType = 'month';
          this.selectedDateWidth = this.selectedDateWidthDefault;
          $selectOption ? $selectOption.value = 'daily' : null;
          break
        case 'hourly':
          selectedLabelAndValue = 'Hourly';
          this.datePickerDateType = 'day';
           this.selectedDateWidth = this.selectedDateWidthDefault;
           $selectOption ? $selectOption.value = 'hourly' : null;
          break;
      }

      this.selectConfigMonthlyDailyHourly.selectedLabel = selectedLabelAndValue;
      this.selectConfigMonthlyDailyHourly.selectedValue = this.drillDepth;
      this.selectConfigMonthlyDailyHourly = JSON.parse(JSON.stringify(this.selectConfigMonthlyDailyHourly));
    },
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    visibleLayers() {
      if (!this.layers) return [];
      return this.layers.filter(item => item.selected);
    },
    visibleLayerNames() {
      return this.visibleLayers.map(item => item.label);
    },
    /** Format the current interval date as a string */
    intervalString() {
      if (!this.intervalDate) return '  ';

      switch (this.drillDepth) {
        case "monthly":
          if (!this.binsTop) return '   ';
          const lastDrillDepth  = this.getSecondToLastElement(this.drillDropIntervalSelections);

          if (lastDrillDepth && lastDrillDepth !== 'monthly') {
            if (typeof this.selectedMontlyIndex !== 'undefined'
            && this.binsTop
            && this.binsTop.bins) {
              return `${new Date(this.binsTop.bins[this.selectedMontlyIndex].date).getFullYear()}`;
            }
          } else {
            return this.usageYear.toString();
          }
        case "13months":
        if (!this.binsTop) return '  ';
          const bins = this.binsTop.bins;
          const start = format(bins[0].date, "MMM yyyy");
          const end = format(bins[bins.length - 1].date, "MMM yyyy");
          return `${start} to ${end}`;
        case "yearly":
          return `${this.usageYear - (USAGE_YEARLY_RANGE_IN_YEARS - 1)} to ${this.usageYear}`;
        case "daily":
          return format(this.intervalDate, "MMM yyyy");
        case "hourly":
          return format(this.intervalDate, "MMM d, yyyy");
      }
    },
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
  },
  async mounted() {
    try {
      // Before we do anything else, check to see if component is operational
      const status = await GetComponentStatus("Usage");
      if (status !== "operational" && !(this.isAdmin)) {
        this.loadState = status;
        this.disableAllDateNavControls = true;
        return;
      }

      this.billingPeriods = await this.getBillingPeriods(this.currentAccount.accountNumber);
      if (!this.billingPeriods || !this.billingPeriods.periods ||  this.billingPeriods.periods.length === 0) {
        this.loadState = 'empty';
        return;
      } 

      this.disabledDates = this.disableDatePickerDatesOutsideBillingPeriods(this.billingPeriods.periods);
      this.RefreshData();

    } catch (err) {
      DumpError("Usage detail graph refresh error", err);
      if (err.response && err.response.status === 404) {
        this.loadState = 'unavailable';
      } else {
        this.loadState = "error";
      }
      return;
    }
  },
  methods: {
    getSecondToLastElement(arr) {
      if (arr.length && arr.length >= 2) {
        return arr[arr.length - 2];
      } else {
        return undefined;
      }
    },
    updateDrillDropIntervalSelections(selection) {
      this.drillDropIntervalSelections.push(selection);

      if (this.drillDropIntervalSelections.length > 2) {
        this.drillDropIntervalSelections.shift();
      }
    },
    async RefreshData() {
      // Reset all data and start at current year
      this.usageYear = new Date().getFullYear();
      this.drillDepth = "monthly";
      this.datePickerDateType = "year";
      this.layers = undefined;
      this.binsTop = undefined;
      this.binsMiddle = undefined;
      this.binsBottom = undefined;
      this.selectedMiddle = undefined;
      this.selectedBottom = undefined;
      this.showMenu = false;
      this.intervalDate = undefined;
      this.drillDropInterval = "monthly";
      this.loadState = undefined;

      // Load options from query parameters
      const params = new URLSearchParams(window.location.search);
      // Note - "account" is already handled in userrefresh.js
      if (params.has("startDate")) {
        const tempstart = parseISO(params.get("startDate"));
        // Ignore if date is in the future
        if (!isFuture(tempstart)) {
          this.queryStartDate = tempstart;
          this.usageYear = this.queryStartDate.getFullYear();
        }
      }
      if (this.queryStartDate && params.has("resolution")) {
        this.queryResolution = params.get("resolution");
      }
      if (params.has("layers")) {
        this.queryLayers = params.get("layers").split(",");
      }
      // Remove the query params and replace the URL
      params.delete("startDate");
      params.delete("resolution");
      params.delete("layers");
      let query = params.toString();
      if (query) query = "?" + query;
      const truncated = window.location.origin + window.location.pathname + query + window.location.hash;
      window.history.replaceState(null, "", truncated);

      const yearStart = new Date(this.usageYear, 0, 1);
      const yearEnd = new Date(this.usageYear, 11, 31);

      try {
        await this.UpdateChart(yearStart, yearEnd);
        return;
      } catch (err) {
        try {
          await this.UpdateChart(subYears(yearStart, 1), subYears(yearEnd, 1));
          this.usageYear = subYears(yearStart, 1).getFullYear();
          return;
        } catch (err) {
          if (err && err.response && err.response.data && err.response.data.errorCode === "MDM_NO_ACTIVE_SERVICES") {
            this.loadState = "unavailable";
          } else if (err && err.response && err.response.data && err.response.data.errorCode === "NO_DATA_FOUND" || err.response && err.response.status === 404) {
            this.loadState = 'empty';
          } else {
            this.handleRefreshError(err);
          }
          return;
        }
      }
    },
    async UpdateChart(yearStart, yearEnd) {
      await this.UpdateTopChart("monthly", yearStart, yearEnd);
      if (!this.binsTop) { 
        this.loadState = 'empty';
        return; 
      }
      this.layers = GetAllRates(this.binsTop.bins);
      // Sometimes (acct 1001520000) usage comes back valid but empty of data
      if (this.layers.length === 0) {
        this.loadState = "empty";
        return;
      } else {
        this.loadState = "complete";
      }
      if (this.queryLayers) {
        // Make sure there is an intersection between queried and available layers
        if (this.queryLayers.find(queried => this.layers.find(layer => layer.name === queried))) {
          // If so, set layer selection according to queried layer names
          for (const layer of this.layers) {
            layer.selected = this.queryLayers.includes(layer.name);
          }
        }
      }
      // If query string says to drill down, fake clicks to do so
      if (this.queryResolution) {
        if (this.queryResolution === "daily" || this.queryResolution === "hourly") {
          let month = this.binsTop.bins.length - 1;
          if (this.queryStartDate) {
            month = this.queryStartDate.getMonth();
          }
          // First drill down to daily view
          await this.ClickTop(month);
          if (this.queryResolution === "hourly") {
            let day = this.binsTop.bins.length - 1;
            if (this.queryStartDate) {
              day = this.queryStartDate.getDate() - 1;
            }
            // Finally drill down to hourly view
            await this.ClickTop(day);
          }
        }
      }
    },
    /** Perform a drilldown on an interval of the top chart */
    async ClickTop(index) {
      if (this.binsTop.bins.length <= index) return;
      const date = this.binsTop.bins[index].date;
      // Disallow future dates
      if (date > startOfToday()) return;

      this.selectedMontlyIndex = undefined;

      if (this.drillDepth === 'yearly') {
          const fullYear = new Date(this.binsTop.bins[index].date).getFullYear();
          const yearStart = new Date(fullYear, 0, 1);
          const yearEnd = new Date(fullYear, 11, 31);

          this.usageYear = fullYear;
          this.selectedMontlyIndex = index;
          this.drillDepth = "monthly";
          this.drillDropInterval = "monthly";

          this.updateDrillDropIntervalSelections('monthly');

          await this.UpdateTopChart('monthly', yearStart, yearEnd, true);
      } else if (this.drillDepth === "monthly" || this.drillDepth === "13months") {
        this.drillDepth = "daily";
        this.drillDropInterval = "daily";

        this.updateDrillDropIntervalSelections('daily');

        // Shift data / selections down
        this.binsMiddle = this.binsTop;
        this.selectedMiddle = index;

        // Refresh the top chart with the selected data
        await this.UpdateTopChart("daily", date, addSeconds(addMonths(date, 1), -1));
      } else if (this.drillDepth === "daily") {
        this.drillDepth = "hourly";
        this.drillDropInterval = "hourly";

        this.updateDrillDropIntervalSelections('hourly');

        // Shift data / selections down
        this.binsBottom = this.binsMiddle;
        this.selectedBottom = this.selectedMiddle;
        this.binsMiddle = this.binsTop;
        this.selectedMiddle = index;

        // Refresh the top chart with the selected data
        await this.UpdateTopChart("hourly", date, addSeconds(addDays(date, 1), -1));
      } else if (this.drillDepth === "hourly") {
        // Already drilled down fully, do nothing
      }
    },
    /** Perform a selection on an interval of the middle chart */
    async ClickMiddle(index) {
      let date = undefined;
      if (!this.binsMiddle || !this.binsMiddle.bins) {
        const [monthStr, yearStr] = this.intervalString.split(' ');
        date = new Date(`${monthStr} 1, ${yearStr}`);
      } else {
        date = this.binsMiddle.bins[index].date;
      }

      // Disallow future dates
      if (date > startOfToday()) return;
      if (this.drillDepth === "daily") {
        this.selectedMiddle = index;

        // Refresh the top chart with the selected data
        await this.UpdateTopChart("daily", date, addSeconds(addMonths(date, 1), -1));
      } else if (this.drillDepth === "hourly") {
        this.selectedMiddle = index;

        // Refresh the top chart with the selected data
        await this.UpdateTopChart("hourly", date, addSeconds(addDays(date, 1), -1));
      }
    },
    /**
     * Perform a selection on an interval of the bottom chart
     * rightmost should be true if scrolling left, ie select the rightmost item
     */
    async ClickBottom(index, rightmost) {
      let date = undefined;
      if (!this.binsBottom || !this.binsBottom.bins) {
         date = new Date(this.intervalString);
      } else {
        date = this.binsBottom.bins[index].date;
      }
      // Disallow future dates
      if (date > startOfToday()) return;
      if (this.drillDepth === "hourly") {
        this.selectedBottom = index;
        let topdate = date;
        if (rightmost) {
          topdate = addDays(addMonths(date, 1), -1);
        }

        // Refresh the middle and top charts
        await Promise.all([
          this.UpdateMiddleChart("daily", date, addSeconds(addMonths(date, 1), -1), rightmost),
          this.UpdateTopChart("hourly", topdate, addSeconds(addDays(topdate, 1), -1)),
        ]);
      }
    },
    /** Refresh usage data for the top chart */
    async UpdateTopChart(interval, dateStart, dateEnd, rightmost) {
      this.updatingChart = true;
      this.binsTop = undefined;
      this.intervalDate = dateStart;
      this.disableAllDateNavControls = true;

      const usage = await this.getPeriodUsageData(this.currentAccount.accountNumber, interval, dateStart, dateEnd);

      if (!usage || !usage.intervals || !usage.intervals.length) {
        this.loadState = 'empty';
        this.disableDateNavigationButtonBack = true;
      } else {
        this.loadState = 'complete';
        this.binsTop = { interval, bins: usage.intervals[0].values };
        if (rightmost) {
          // We never select the top chart, but rightmost means scroll to the right (ie if we've navigated over from the interval to the right)
          this.$refs.chartTop.ScrollFarRight();
        }
      }

      this.updatingChart = false;
      this.disableAllDateNavControls = false;
    },
    /**
     * Only called when clicking the bottom chart. Ordinarily, middle chart is populated with the existing data from the top chart.
     * rightmost should be true if scrolling left, ie select the rightmost item
     */
    async UpdateMiddleChart(interval, dateStart, dateEnd, rightmost) {
      // Set middle chart to "loading"
      this.binsMiddle = undefined;

      const usage = await this.getPeriodUsageData(this.currentAccount.accountNumber, interval, dateStart, dateEnd);

      if (!usage || !usage.intervals || !usage.intervals.length) {
        this.loadState = 'empty';
      } else {
        this.loadState = 'complete';
        this.binsMiddle = { interval, bins: usage.intervals[0].values };
        this.selectedMiddle = rightmost ? this.binsMiddle.bins.length - 1 : 0;
      }

    },
    async UpdateBottomChart(interval, dateStart, dateEnd, rightmost) {
      // Set bottom chart to "loading"
      this.binsBottom = undefined;

      const usage = await this.getPeriodUsageData(this.currentAccount.accountNumber, interval, dateStart, dateEnd);

      if (!usage || !usage.intervals || !usage.intervals.length) {
        this.loadState = 'empty';
      } else {
        this.loadState = 'complete';
        this.binsBottom = { interval, bins: usage.intervals[0].values };
        this.selectedBottom = rightmost ? this.binsBottom.bins.length - 1 : 0;
      }
    },
    /** Right nav button clicked */
    dateForwards() {
      if (!this.$refs.chartTop) {
        this.IntervalAdjust("right");
      } else {
        this.$refs.chartTop.Navigate("right");
      }
    },
    /** Left nav button clicked */
    dateBackwards() {
      if (!this.$refs.chartTop) {
        this.IntervalAdjust("left");
      } else {
        this.$refs.chartTop.Navigate("left");
      }
    },
    /**
     * Navigate left or right of the current topmost interval
     * Fired by the chart itself if unable to scroll the scrollbar any more
     */
    async IntervalAdjust(direction) {
      if (!this.intervalDate) return;
      if (direction === "left") {

        if (this.drillDepth === "yearly") {
          this.usageYear--;
          this.updateChartToYearlyView(startOfYear(parseISO(`${this.usageYear}-01-01`)));
          return;
        } else if ((this.drillDepth === "daily" || this.drillDepth === "hourly") && this.selectedMiddle > 0) {
          // Room to go left on the middle track
          this.ClickMiddle(this.selectedMiddle - 1);
        } else {
          if (this.drillDepth === "hourly" && this.selectedBottom > 0) {
            // Room to go left on the bottom track - wrap the middle track around to rightmost
            this.ClickBottom(this.selectedBottom - 1, true);
          } else {
            if (this.drillDepth === 'daily' && this.dateRange13months) {
              const lastMonth = subMonths(this.binsMiddle.bins[0].date, 1);
              const lastDayOfLastMonth = lastDayOfMonth(lastMonth);
              this.updateChartToDailyView(startOfDay(lastDayOfLastMonth), false, false);

              return;
            }

            // Go to previous year
            this.usageYear--;
            const today = new Date();
            const yearStart = new Date(this.usageYear, 0, 1);
            const yearEnd = new Date(this.usageYear, 11, 31);

            if (this.drillDepth === "monthly") {
              await this.UpdateTopChart("monthly", yearStart, yearEnd, true);
            } else if (this.drillDepth === "13months") {
              const bins = this.binsTop.bins;
              const currentEndDate = new Date(bins[bins.length - 1].date);
              const firstDayOfPreviousMonth = subMonths(currentEndDate, 1);

              this.updateChartTo13MonthView(startOfMonth(firstDayOfPreviousMonth));
            } else if (this.drillDepth === "daily") {
              // Refresh the middle and top charts
              this.updateChartToDailyView(yearEnd, false, true);
            } else if (this.drillDepth === "hourly") {
              if (this.dateRange13months) {
                const lastMonth = subMonths(this.binsMiddle.bins[0].date, 1);
                const lastDayOfLastMonth = lastDayOfMonth(lastMonth);
                this.updateChartToHourlyView(startOfDay(lastDayOfLastMonth), false, true);
              } else {
                // Refresh all charts
                // Why does this pass in today????????
                // this.updateChartToHourlyView(today, false, true);
                this.updateChartToHourlyView(yearEnd, false, true);
              }
            }
          }
        }
      } else if (direction === "today") {
        const today = new Date();
        const yearStart = startOfYear(today);
        const yearEnd = endOfYear(today);
        this.usageYear = today.getFullYear();

        if (this.drillDepth === "yearly") {
          this.updateChartToYearlyView(startOfYear(parseISO(`${this.usageYear}-01-01`)));
        } else if (this.drillDepth === "monthly") {
          await this.UpdateTopChart("monthly", yearStart, yearEnd);
        }  else if (this.drillDepth === "13months") {
          this.updateChartTo13MonthView(today, true, false);
        } else if (this.drillDepth === "daily") {
          this.updateChartToDailyView(today, true, false);
        } else if (this.drillDepth === "hourly") {
          // Refresh all charts
          this.updateChartToHourlyView(today, true, false);
        }
      } else {
        this.disableDateNavigationButtonBack = false;

        if (this.drillDepth === "yearly") {
          this.usageYear++;
          this.updateChartToYearlyView(startOfYear(parseISO(`${this.usageYear}-01-01`)));
          return;
        }

        if ((this.drillDepth === "daily" || this.drillDepth === "hourly") && this.binsMiddle && this.selectedMiddle < this.binsMiddle.bins.length - 1) {
          // Room to go right on the middle track
          this.ClickMiddle(this.selectedMiddle + 1);
        } else {
          if (this.drillDepth === "hourly" && this.binsBottom && this.selectedBottom < this.binsBottom.bins.length - 1) {
            // Room to go right on the bottom track (will also refresh middle track)
            this.ClickBottom(this.selectedBottom + 1);
          } else {
            if (this.drillDepth === '13months') {
              const bins = this.binsTop.bins;
              const currentEndDate = new Date(bins[bins.length - 1].date);
              const nextMonth = addMonths(currentEndDate, 1);
              const lastDayOfNextMonth = endOfMonth(nextMonth);

              this.updateChartTo13MonthView(startOfDay(lastDayOfNextMonth));

              return;
            }

            if (this.drillDepth === 'daily' && this.dateRange13months) {
              const bins = this.binsMiddle.bins;
              const firstDayOfNextMonth = addMonths(bins[bins.length - 1].date, 1);
              this.updateChartToDailyView(startOfMonth(firstDayOfNextMonth), false, false);

              return;
            }

            if (this.drillDepth === 'hourly' && this.dateRange13months) {
              const bins = this.binsBottom.bins;
              const firstDayOfNextMonth = addMonths(bins[bins.length - 1].date, 1);
              this.updateChartToHourlyView(startOfMonth(firstDayOfNextMonth), false, true);

              return;
            }

            // Disallow future dates
            if (new Date(this.usageYear + 1, 0, 1) > startOfToday()) return;

            // Go to next year
            this.usageYear++;
            const yearStart = new Date(this.usageYear, 0, 1);
            const yearEnd = new Date(this.usageYear, 11, 31);

            if (this.drillDepth === "monthly") {
              await this.UpdateTopChart("monthly", yearStart, yearEnd);
            } else if (this.drillDepth === "daily") {
              // Refresh the middle and top charts
              this.updateChartToDailyView(yearStart, false, false);
            } else if (this.drillDepth === "hourly") {
              // Refresh all charts
              this.updateChartToHourlyView(yearStart, false, false);
            }
          }
        }
      }
    },
    selectOptionMonthlyDailyHourly($event) {
      this.drillDropInterval = $event;
      this.updateDrillDropIntervalSelections($event);
      this.DrillDropChange();
    },
    /** Handle changing intervals with the dropdown rather than clicking */
    async DrillDropChange() {
      const oldInterval = this.drillDepth;
      const newInterval = this.drillDropInterval;
      const yesterday = startOfYesterday();

      switch (oldInterval) {
        case "yearly":
          switch (newInterval) {
            case "yearly":
              // no change
            break;
            case "monthly":
              this.drillDepth = "monthly";
              this.dateRange13months = false;
              this.updateChartToMonthlyView(yesterday, true, false);
              break;
            case "13months":
              this.drillDepth = "13months";
              this.dateRange13months = true;
              this.setDatePickerDefaultDate(new Date());
              this.updateChartTo13MonthView(yesterday, true, false);
              break;
            case "daily":
              // Going down, always refresh to target yesterday
              this.usageYear = yesterday.getFullYear();
              this.drillDepth = "daily";
              this.setDatePickerDefaultDate(yesterday);
              this.updateChartToDailyView(yesterday, true, false);
              break;
            case "hourly":
              // Going down, always refresh to target yesterday
              this.usageYear = yesterday.getFullYear();
              this.drillDepth = "hourly";
              this.setDatePickerDefaultDate(yesterday);
              this.updateChartToHourlyView(yesterday, true, false);
              break;
          }
          break;
        case "monthly":
          switch (newInterval) {
            case "monthly":
              // No change
              break;
            case "13months":
              this.drillDepth = "13months";
              this.dateRange13months = true;
              this.setDatePickerDefaultDate(new Date());
              this.updateChartTo13MonthView(yesterday, true, false);
              break;
            case "yearly":
              this.drillDepth = "yearly";
              this.usageYear = yesterday.getFullYear();
              this.setDatePickerDefaultDate(yesterday);
              this.updateChartToYearlyView(yesterday, true, false);
              break;
            case "daily":
              // Going down, always refresh to target yesterday
              this.usageYear = yesterday.getFullYear();
              this.drillDepth = "daily";
              this.setDatePickerDefaultDate(yesterday);
              this.updateChartToDailyView(yesterday, true, false);
              break;
            case "hourly":
              // Going down, always refresh to target yesterday
              this.usageYear = yesterday.getFullYear();
              this.drillDepth = "hourly";
              this.setDatePickerDefaultDate(yesterday);
              this.updateChartToHourlyView(yesterday, true, false);
              break;
          }
          break;
        case "13months":
          switch (newInterval) {
            case "monthly":
              this.drillDepth = "monthly";
              this.dateRange13months = false;
              this.updateChartToMonthlyView(yesterday, true, false);
              break;
            case "13months":
              // No change
              break;
            case "yearly":
              this.drillDepth = "yearly";
              this.usageYear = yesterday.getFullYear();
              this.setDatePickerDefaultDate(yesterday);
              this.updateChartToYearlyView(yesterday, true, false);
              break;
            case "daily":
              // Going down, always refresh to target yesterday
              this.usageYear = yesterday.getFullYear();
              this.drillDepth = "daily";
              this.setDatePickerDefaultDate(yesterday);
              this.updateChartToDailyView(yesterday, true, false);
              break;
            case "hourly":
              // Going down, always refresh to target yesterday
              this.usageYear = yesterday.getFullYear();
              this.drillDepth = "hourly";
              this.setDatePickerDefaultDate(yesterday);
              this.updateChartToHourlyView(yesterday, true, false);
              break;
          }
          break;
        case "daily":
          switch (newInterval) {
            case "monthly":
              this.binsTop = this.binsMiddle;
              this.selectedTop = this.selectedMiddle;
              this.drillDepth = "monthly";
              this.dateRange13months = false;
              this.setDatePickerDefaultDate(yesterday);
              this.updateChartToMonthlyView(yesterday, true, false);
              break;
            case "13months":
              this.binsTop = this.binsMiddle;
              this.selectedTop = this.selectedMiddle;
              this.drillDepth = "13months";
              this.dateRange13months = true;
              this.setDatePickerDefaultDate(new Date());
              this.updateChartTo13MonthView(yesterday, true, false);
              break;
            case "yearly":
              this.drillDepth = "yearly";
              this.usageYear = yesterday.getFullYear();
              this.setDatePickerDefaultDate(yesterday);
              this.updateChartToYearlyView(yesterday, true, false);
              break;
            case "daily":
              // No change
              break;
            case "hourly":
              // Going down, always refresh to target yesterday
              this.usageYear = yesterday.getFullYear();
              this.drillDepth = "hourly";
              this.setDatePickerDefaultDate(yesterday);
              this.updateChartToHourlyView(yesterday, true, false);
              break;
          }
          break;
        case "hourly":
          switch (newInterval) {
            case "monthly":
              this.binsTop = this.binsBottom;
              this.selectedTop = this.selectedBottom;
              this.drillDepth = "monthly";
              this.dateRange13months = false;
              this.setDatePickerDefaultDate(yesterday);
              this.updateChartToMonthlyView(yesterday, true, false);
              break;
            case "13months":
              this.binsTop = this.binsBottom;
              this.selectedTop = this.selectedBottom;
              this.drillDepth = "13months";
              this.dateRange13months = true;
              this.setDatePickerDefaultDate(yesterday);
              this.updateChartTo13MonthView(yesterday, true, false);
              break;
            case "yearly":
              this.drillDepth = "yearly";
              this.usageYear = yesterday.getFullYear();
              this.setDatePickerDefaultDate(yesterday);
              this.updateChartToYearlyView(yesterday, true, false);
              break;
            case "daily":
              this.binsTop = this.binsMiddle;
              this.selectedTop = this.selectedMiddle;
              this.binsMiddle = this.binsBottom;
              this.selectedMiddle = this.selectedBottom;
              this.drillDepth = "daily";
              break;
            case "hourly":
              // No change
              break;
          }
          break;
      }
    },
    async selectDate($event) {
      this.usageYear = $event.getFullYear();
      switch (this.drillDepth) {
        case "monthly":
          await this.UpdateTopChart("monthly", startOfYear($event), endOfYear($event));
          break;
        case "13months":
          await this.updateChartTo13MonthView($event, false, false);
          break;
        case "yearly":
          await this.updateChartToYearlyView($event, false, false);
          break;
        case "daily":
          this.drillDepth = "daily";
          this.updateChartToDailyView($event, true, false);
          break;
        case "hourly":
          this.drillDepth = "hourly";
          this.updateChartToHourlyView($event, true, false)
          break;
      }
    },
    setDatePickerDefaultDate(targetDate) {
      this.datePickerDefaultDate = new Date(endOfDay(targetDate));
    },
    get13MonthStartDate(endDate) {
      const startDate = subMonths(endDate, 12);
      const startOfStartDate = startOfMonth(startDate);
      return startOfStartDate;
    },
    getMonthStartAndEndDates(dateStart) {
      return {
        end: this.dateRange13months ? endOfMonth(dateStart) : endOfYear(dateStart),
        start: this.dateRange13months ? this.get13MonthStartDate(dateStart) : startOfYear(dateStart)
      };
    },
    async updateChartToYearlyView(dateStart) {
      const dateFiveYearsAgo = subYears(dateStart, (USAGE_YEARLY_RANGE_IN_YEARS - 1));
      const firstDayOfYearFiveYearsAgo = startOfYear(dateFiveYearsAgo);

      await Promise.all([
        this.UpdateTopChart("yearly",firstDayOfYearFiveYearsAgo, endOfYear(dateStart)),
      ]);
    },
    async updateChartToMonthlyView(dateStart, setSelected, rightmost) {
      await Promise.all([
        this.UpdateTopChart("monthly", startOfYear(dateStart), endOfYear(dateStart)),
      ]);
    },
    async updateChartTo13MonthView(dateStart, setSelected, rightmost) {
      await Promise.all([
        this.UpdateTopChart("monthly", this.get13MonthStartDate(dateStart) , endOfMonth(dateStart)),
      ]);
    },
    async updateChartToDailyView(dateStart, setSelected, rightmost) {
      const monthDates = this.getMonthStartAndEndDates(dateStart);

      await Promise.all([
        this.UpdateMiddleChart("monthly", monthDates.start, monthDates.end, rightmost),
        this.UpdateTopChart("daily", startOfMonth(dateStart), endOfMonth(dateStart)),
      ]);

      if (setSelected && !this.dateRange13months) {
        this.selectedMiddle = dateStart.getMonth();
      } else if (this.dateRange13months) {
        this.selectedMiddle = this.selectedIndexFor13months;
      }
    },
    async updateChartToHourlyView(dateStart, setSelected, rightmost) {
      const monthDates = this.getMonthStartAndEndDates(dateStart);
      const rightmostMiddle = this.dateRange13months ? false : rightmost;

      await Promise.all([
        this.UpdateBottomChart("monthly", monthDates.start, monthDates.end, rightmost),
        this.UpdateMiddleChart("daily", startOfMonth(dateStart), endOfMonth(dateStart), rightmostMiddle),
        this.UpdateTopChart("hourly", startOfDay(dateStart), endOfDay(dateStart)),
      ]);

      if (setSelected) {
        this.selectedMiddle = dateStart.getDate() - 1;

        if (!this.dateRange13months) {
          this.selectedBottom = dateStart.getMonth();
        } else if (this.dateRange13months) {
          this.selectedBottom = this.selectedIndexFor13months;
        }
      }
    },
    goToToday() {
      this.IntervalAdjust("today");
      this.datePickerDefaultDate = new Date();
    },
    viewOptions() {
      // Check value
      if (this.showMenu) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    },
    closeLayersMenu() {
      this.showMenu = false;
    },
    updateSelected(evt) {
      this.layers = evt;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>