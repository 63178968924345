<template>
  <div class="usage-dashboard__widget-current-usage">
      <div class="header">
      <h2>Performance this Billing Period</h2>
      <a href="/account/usage-new/detail/performance"
      class="usage-dashboard__widget-current-usage--circle-arrow-right gds-button gds-button-circle gds-button-outline">
      <svg class="gds-icon">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right" />
        </svg>
      </a>
      </div>
      <div v-if="currentUsageData && loadState === 'complete'">
        <div class="summary">
        <div class="usage-dashboard__widget-current-usage--header-performace">{{ totalCurrentUsage | numberCommaSingleDecimal}} kWh
        </div>
        <div class="usage-dashboard__kwhDifference-container">
          <div v-if="differenceInUsage.language === 'less'" class="usage-dashboard__widget-current-usage--checkmark--green">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.76479 10.5883L6.35303 12.0001L10.2354 16.2354L17.6471 9.17654L16.2354 7.76477L10.2354 13.4118L7.76479 10.5883Z" fill="white"/>
            </svg>
          </div>
          <div v-if="differenceInUsage.language === 'more'" class="usage-dashboard__widget-current-usage--arrow--blue-circle-up gds-button gds-button-circle" ></div>
          <div class="usage-dashboard__widget-current-usage--kwhDifference--flex-item">
          You used {{ differenceInUsage.difference }} kWh {{ differenceInUsage.language }} than last billing period.
          </div>
        </div>
        </div>
        
      <div class="usage-dashboard__widget-chart-container">
        <div id="usage-dashboard__widget-current-usage--chart" ref="currentUsageChartRef" />
      </div>
    </div>
    <div v-if="!loadState" class="usage-dashboard__widget-current-usage my-account__usage-loading" />
    <div v-if="loadState === 'unavailable'" class="error-message">Usage information is not available for this account.</div>
    <div v-if="loadState === 'error'" class="error-message">Unable to load, please try again later.</div>
  </div>
</template>

<script>

import { DrawChart } from "./widgetcurrentusage";
import { format, parseISO } from "date-fns";
import { DumpError } from "../../../../utilities";
import UsageDetailLegendItem from "../usagedetailcompare/UsageDetailLegendItem";
import MediaQueryMixin from "../../../mixins/MediaQueryMixin";
import UsageDashboardMixin from "../../../mixins/UsageDashboardMixin";
import WidgetNetMeteringMixin from "../widgetnetmetering/WidgetNetMeteringMixin";
export default {
  components: { UsageDetailLegendItem },
  name: "WidgetCurrentUsage",
  components: {
    UsageDetailLegendItem
  }, 
  mixins: [
    WidgetNetMeteringMixin,
    MediaQueryMixin,
    UsageDashboardMixin
  ],
  data() {
    return {
      currentLabel: undefined,
      previousLabel: undefined,
      totalCurrentUsage: undefined,
      totalPreviousUsage: undefined,
      noData: undefined,
      currentUsageData: undefined,
      previousBillingCycleDates: undefined,
      currentBillingCycleDates: undefined,
      chartDataPrevious: undefined,
      chartDataCurrent: undefined,
      differenceInUsage: {
        difference: undefined,
        language: undefined
      },
      currentPeriodLegendText: undefined,
      previousPeriodLegendText: undefined,
      loadState: undefined,
    }
  },
  watch: {
    currentAccount() {
      this.RefreshChart();
    }
  },
  async mounted() {
    window.addEventListener('resize', this.throttle(this.onResize, 50));
    this.RefreshChart();
  },
  methods: {
    onResize() {
      this.setLegendLabels();
    },
    setLegendLabels() {
      if (this.deviceIsSmall) {
        this.currentLabel = 'Current';
        this.previousLabel = 'Previous'
      } else {
        this.currentLabel = '';
        this.previousLabel = '';
      }
    },
    async RefreshChart() {
      this.loadState = undefined
      try {
        await this.getBillingCycleDates();
        const chartData = await this.getDailyPeriodUsageRateData('consumedTotal');
        this.chartDataPrevious = chartData.previousRateData;
        this.chartDataCurrent = chartData.currentRateData;
        this.loadState = 'complete'
      } catch (err) {
        if (err.response && err.response.status === 404) {
          this.loadState = "unavailable";
          return;
        }
        this.currentUsageData = undefined;
        this.loadState = 'error'
        DumpError("Error getting billing period/usage data.", err);
      }
      if (!this.currentUsageData) { return; }

      this.totalCurrentUsage = this.getTotalCycleUsage(this.currentBillingCycleDailyUsageData);
      this.totalPreviousUsage = this.getTotalCycleUsage(this.previousBillingCycleDailyUsageData.slice(0, this.currentBillingCycleDailyUsageData.length));
      this.differenceInUsage = this.getDifferenceInUsage(this.totalPreviousUsage, this.totalCurrentUsage);

      this.setLegendLabels();

      this.currentPeriodLegendText = await this.formatDates(this.currentBillingCycleDates.startDate, this.currentBillingCycleDates.endDate);

      this.previousPeriodLegendText = await this.formatDates(this.previousBillingCycleDates.startDate, this.previousBillingCycleDates.endDate);

      DrawChart(this.$refs.currentUsageChartRef, this.deviceIsSmall, this.chartDataPrevious, this.chartDataCurrent, 
                    this.currentBillingCycleDates.startDate, this.previousBillingCycleDailyUsageData, this.currentBillingCycleDailyUsageData);
      this.changeChartXAxis0Indexto1();
    },
    formatDates(startDate, endDate) {
      if (this.deviceIsSmall) {
        return ` ${format(parseISO(startDate), 'MMM. d, yyyy')} - ${format(parseISO(endDate), 'MMM. d, yyyy')}`;
      }
      return `${this.currentLabel} ${format(parseISO(startDate), 'MMMM d, yyyy')} - ${format(parseISO(endDate), 'MMMM d, yyyy')}`;
    },
    getTotalCycleUsage(chartData) {
      let totalUsage = 0;
      for (let i = 0; i < chartData.length; i++) {
        totalUsage += chartData[i].consumedTotal;
      }
      return parseFloat(totalUsage.toFixed(1));
    },
    getDifferenceInUsage(previousUsage, currentUsage) {
      if (currentUsage > previousUsage) {
        return {
          difference: Math.round(currentUsage - previousUsage),
          language: 'more'
        }
      } else {
        return {
          difference: Math.round(previousUsage - currentUsage),
          language: 'less'
        }
      }
    },
    changeChartXAxis0Indexto1() {
      document.querySelector(".usage-dashboard__widget-current-usage g.highcharts-axis-labels.highcharts-xaxis-labels > text:nth-child(1)").innerHTML = 1;
    },
    throttle(func, wait) {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>