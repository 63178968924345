var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "gmp-modal" }, [
        _c(
          "div",
          { staticClass: "export-data__modal-box" },
          [
            _c("FocusLock", [
              _c("header", { staticClass: "export-data__modal-box--header" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "close bill-pay__close gds-button gds-button-circle gds-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.Close()
                      }
                    }
                  },
                  [
                    _c("svg", { staticClass: "gds-icon close-icon" }, [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                        }
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "export-data__modal-box--headline" }, [
                  _vm._v("Usage Download")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "export-data__modal-box--body" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "export-data__modal-box--message gds-space-stack-l gds-flex-container gds-flex-container--space-between"
                  },
                  [
                    _c("div", { staticClass: "gds-font-demi" }, [
                      _vm._v("Select a Format")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "gds-flex-container gds-flex-container--right"
                      },
                      [
                        _c("button", {
                          staticClass:
                            "export-data__data-format-selection green-xml-icon gds-button gds-secondary gds-compact gds-space-inline-s",
                          class: {
                            "export-data__active": _vm.dataFormat === "xml"
                          },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.dataFormat = "xml"
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "export-data__data-format-selection gds-button gds-secondary gds-compact",
                            class: {
                              "export-data__active": _vm.dataFormat === "csv"
                            },
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                _vm.dataFormat = "csv"
                              }
                            }
                          },
                          [_vm._v("CSV")]
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "gds-font-demi gds-space-stack-s" }, [
                  _vm._v("Date Range")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gds-font-size-s gds-space-stack-s" },
                  [_vm._v("Maximum range: 15 days")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "export-data__modal-box--message gds-space-stack-s gds-flex-container gds-flex-container--space-between"
                  },
                  [
                    _c(
                      "fieldset",
                      { staticClass: "export-data__fieldset gds-fieldset" },
                      [
                        _c("div", { staticClass: "gds-input-field__label" }, [
                          _vm._v("From")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "export-data__filtration-date-box" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "my-account-billing-report__filtration-date-icon",
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.startPicker.showCalendar()
                                  }
                                }
                              },
                              [
                                _c("svg", { staticClass: "gds-icon" }, [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar"
                                    }
                                  })
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("datepicker", {
                              ref: "startPicker",
                              attrs: {
                                format: "MMMM d, yyyy",
                                "disabled-dates": {
                                  from: _vm.lastPossibleDate
                                },
                                "input-class":
                                  "gds-font-size-s export-data__date"
                              },
                              model: {
                                value: _vm.startDate,
                                callback: function($$v) {
                                  _vm.startDate = $$v
                                },
                                expression: "startDate"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "fieldset",
                      { staticClass: "export-data__fieldset gds-fieldset" },
                      [
                        _c("div", { staticClass: "gds-input-field__label" }, [
                          _vm._v("To")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "export-data__filtration-date-box" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "my-account-billing-report__filtration-date-icon",
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.endPicker.showCalendar()
                                  }
                                }
                              },
                              [
                                _c("svg", { staticClass: "gds-icon" }, [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar"
                                    }
                                  })
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("datepicker", {
                              ref: "endPicker",
                              attrs: {
                                format: "MMMM d, yyyy",
                                "disabled-dates": {
                                  from: _vm.lastPossibleDate
                                },
                                "input-class":
                                  "gds-font-size-s export-data__date"
                              },
                              model: {
                                value: _vm.endDate,
                                callback: function($$v) {
                                  _vm.endDate = $$v
                                },
                                expression: "endDate"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.rangeDays < 1
                  ? _c("div", { staticClass: "validation-error" }, [
                      _vm._v("Start date must be before end date.")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.rangeDays > 15
                  ? _c("div", { staticClass: "validation-error" }, [
                      _vm._v("Maximum export range is 15 days.")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "gds-button gds-compact gds-space-stack-l",
                    class: { "gds-loading": _vm.pendingDownload },
                    attrs: {
                      disabled:
                        _vm.rangeDays < 1 ||
                        _vm.rangeDays > 15 ||
                        _vm.pendingDownload,
                      type: "button"
                    },
                    on: {
                      click: function($event) {
                        return _vm.Save()
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "gds-button__text" }, [
                      _vm._v("Go")
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm.errorDownload
                  ? _c(
                      "div",
                      { staticClass: "form-message-box gds-space-stack-m" },
                      [_vm._v(_vm._s(_vm.errorDownload))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "export-data__xml-green-button-description gds-font-size-s"
                  },
                  [
                    _c("span", {
                      staticClass:
                        "export-data__xml-green-button-description--icon green-xml-icon"
                    }),
                    _vm._v(
                      " Selecting the green button option for your usage download format provides a Green Button Initiative compliant XML dataset.\n        "
                    )
                  ]
                )
              ])
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }