<template>
  <div id="usage-dashboard__widget-monthly-consumption" class="usage-dashboard__widget-monthly-consumption">
    <div class="header">
      <div>
        <h2 v-if="isNetMetered" class="gds-space-stack-default">Monthly Total Home Consumption</h2>
        <h2 v-else class="gds-space-stack-m">Monthly Consumption</h2>
        <p v-if="isNetMetered" class="usage-dashboard___sub-title">Energy Consumed from Grid and Self-Generation</p>
      </div>
      <div class="actions">
        <a href="/account/usage-new/detail/graph" class="gds-desktop-only gds-button gds-button-text gds-compact gds-button-underline "><div class="gds-button-nowrap">
        View Detailed Graph
        </div>
        </a>
        <a href="/account/usage-new/detail/graph" class="gds-button gds-button-circle gds-button-outline">
          <svg class="gds-icon">
            <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right" />
          </svg>
        </a>
      </div>
    </div>
    <div>
      <flow-error v-if="loadState === 'error'" name="Usage information" state="error" />
      <flow-error v-if="loadState === 'maintenance'" name="Usage information" state="maintenance" />
      <flow-error v-if="loadState === 'unavailable'" name="Usage information" state="unavailable"
        img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
      <flow-error v-if="loadState === 'empty'" name="Usage information" state="nodata"
        img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
    </div>
    <div v-if="loadState === 'complete'" class="usage-dashboard__widget-monthly-consumption__chart_container">
      <usage-chart v-if="binsTop" ref="chartTop" :usageData="binsTop" :layers="visibleLayerNames" height="300px" />
    </div>
  </div>
</template>

<script>
import { DumpError } from "../../../../utilities";
import UsageChart from "../usagedetailgraph/UsageChart.vue";
import { GetAllRates } from '../usagedetailgraph/usagedata';
import { GetComponentStatus } from '../../../../services/statuspage';
import UsageDetailBaseComponentMixin from "../../../mixins/UsageDetailBaseComponentMixin";
import {
  subMonths,
  endOfMonth,
  isSameMonth,
  startOfMonth,
  startOfYesterday
} from 'date-fns';

export default {
  name: "WidgetMonthlyConsumption",
  mixins: [
    UsageDetailBaseComponentMixin
  ],
  components: {
    UsageChart
  },
  props: {
    isNetMetered: Boolean
  },
  data() {
    return {
      layers: undefined,
      binsTop: undefined,
      loadState: undefined,
      updatingChart: false
    };
  },
  watch: {
    async currentAccount() {
      await this.RefreshData();
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    visibleLayers() {
      if (!this.layers) return [];
      return this.layers.filter(item => item.selected);
    },
    visibleLayerNames() {
      return this.visibleLayers.map(item => item.label);
    },
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
  },
  async mounted() {
    try {
      const status = await GetComponentStatus("Usage");
      if (status !== "operational" && !(this.isAdmin)) {
        this.loadState = status;
        return;
      }

      this.billingPeriods = await this.getBillingPeriods(this.currentAccount.accountNumber);

      if (!this.billingPeriods || !this.billingPeriods.periods ||  this.billingPeriods.periods.length === 0) {
        this.loadState = 'empty';
        return;
      }

      await this.RefreshData();
    } catch (err) {
      DumpError("Usage detail graph refresh error", err);
      if (err.response && err.response.status === 404) {
        this.loadState = 'unavailable';
      } else {
        this.loadState = "error";
      }
      return;
    }
  },
  methods: {
    async RefreshData() {
      this.layers = undefined;
      this.binsTop = undefined;
      this.loadState = undefined;
      const yesterday = startOfYesterday();

      try {
        await this.updateChartTo13MonthView(yesterday, true, false)
        return;
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.errorCode === "MDM_NO_ACTIVE_SERVICES") {
            this.loadState = "unavailable";
          } else if (err && err.response && err.response.data && err.response.data.errorCode === "NO_DATA_FOUND" || err.response && err.response.status === 404) {
            this.loadState = 'empty';
          } else {
            this.handleRefreshError(err);
          }
          return;
      }
    },
    async UpdateTopChart(interval, dateStart, dateEnd, rightmost) {
      this.updatingChart = true;
      this.binsTop = undefined;
      const usage = await this.getPeriodUsageData(this.currentAccount.accountNumber, interval, dateStart, dateEnd);

      usage.intervals[0].values.forEach(datapoint => {
        delete datapoint.evConsumedTotal;

        if (this.isNetMetered) {
          delete datapoint.onPeak;
          delete datapoint.offPeak;
          delete datapoint.consumed;
          delete datapoint.generation;
          delete datapoint.consumedTotal;
          delete datapoint.returnedGeneration;
        }
      });

      if (!usage || !usage.intervals || !usage.intervals.length) {
        this.loadState = 'empty';
      } else {
        this.loadState = 'complete';
        this.binsTop = { interval, bins: usage.intervals[0].values };
        this.layers = GetAllRates(this.binsTop.bins);
        const $resizableElement = document.getElementById('usage-dashboard__widget-monthly-consumption');
      }

      this.updatingChart = false;
    },
    get13MonthStartDate(endDate) {
      const startDate = subMonths(endDate, 12);
      const startOfStartDate = startOfMonth(startDate);
      return startOfStartDate;
    },
    async updateChartTo13MonthView(dateStart, setSelected, rightmost) {
      await Promise.all([
        this.UpdateTopChart("monthly", this.get13MonthStartDate(dateStart) , endOfMonth(dateStart)),
      ]);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>