<template>
  <div class="my-account__login-page-wrap">
    <!-- This page should be reached with a query string of "?redirect=[url]".
    The user will be redirected upon login.
    For example: transit-login?redirect=/account-->
    <section class="my-account__login-form-wrap">
      <div class="gds-display-2 gds-space-stack-m">My Account</div>
      <div v-if="loadState === undefined" class="table-loading" />
      <flow-error v-if="loadState === 'error'" name="Logging in" state="error" phonetext="To report an outage or make a payment by phone call us at" />
      <flow-error v-if="loadState === 'maintenance'" name="Logging in" state="maintenance" phonetext="To report an outage or make a payment by phone call us at" />
      <div v-if="loadState === 'complete' && userIsLoggedIn">
          <p style="font-weight: bold;">You are already logged in. Do you want to log out, or continue?</p>
          <button @click="Logout()" type="button" class="gds-button gds-secondary gds-space-inline-m"><span class="gds-button__text">Log out</span></button>
          <button @click="Continue()" type="button" class="gds-button gds-space-inline-m"><span class="gds-button__text">Continue</span></button>
        </div>
      <div v-if="loadState === 'complete' && !userIsLoggedIn">
        <div class="gds-font-size-l gds-space-stack-m">
          Log in to view your account information. Don’t have an account?
          <a href="/account/create">Create an account</a>
        </div>
        <form class="global-panel__form" @submit.prevent="Login()">
          <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorlogin }">
            <label class="gds-input-field">
              <span class="gds-input-field__label">Username or Email</span>
              <input
                v-model="username"
                class="gds-input-field__input"
                id="username"
                v-validate="'required'"
                type="text"
                spellcheck="false"
                autocapitalize="none"
                autocomplete="username"
                required
                aria-required="true"
              />
            </label>
          </fieldset>

          <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorlogin }">
            <label class="gds-input-field">
              <span class="gds-input-field__label">Password</span>
              <widget-password-input v-model="password" autocomplete="current-password" />
            </label>
          </fieldset>

          <div class="global-panel__form-helpers gds-flex-container gds-flex-container--space-between gds-space-stack-l">
            <div class="global-login-panel__form-helper">
              <label class="gds-checkbox gds-checkbox-thin">
                <input v-model="stayloggedin" type="checkbox" id="stay-logged-in" />
                <span class="gds-checkbox__faux"></span>
                <span class="gds-checkbox__label gds-display-00 gds-text-grey">Stay Logged In</span>
              </label>
            </div>

            <div class="global-login-panel__form-helper">
              <a href="/account/forgot" class="gds-display-00 gds-link gds-link--bold">Forgot Password?</a>
            </div>
          </div>

          <div class="gds-space-stack-l">
            <div v-if="errorlogin" id="submission_error" class="gds-helper-text-error">{{errorlogin}}</div>
          </div>

          <div class="gds-space-stack-l">
            <button :disabled="pendingLogin" :class="{ 'gds-loading': pendingLogin }" type="submit" class="gds-button">
              <span class="gds-button__text">Log In</span>
            </button>
          </div>

          <div class="global-panel__form-helpers">
            <p class="gds-align--text-center gds-body-small gds-link gds-link--bold">
              Don't have an account?
              <a href="/account/create">Get Started</a>
            </p>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import WidgetPasswordInput from "../WidgetPasswordInput";
import { GetComponentStatus } from '../../services/statuspage';
import { DumpError } from "../../utilities";

export default {
  name: "PageTransitLogin",
  components: {
    WidgetPasswordInput,
  },
  data() {
    return {
      userIsLoggedIn: false,
      destination: undefined,

      username: undefined,
      password: undefined,
      stayloggedin: true,

      errorlogin: undefined,
      pendingLogin: false,

      loadState: undefined,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.user;
    }
  },
  async mounted() {
    this.userIsLoggedIn = !!(this.currentUser && this.currentUser.apitoken);

    const query = window.location.search;
    const params = new URLSearchParams(query);
    this.destination = params.get("redirect");
    // Only allow relative destinations (starts with /)
    if (!this.destination || !this.destination.startsWith("/")) {
      console.log("Fallback to /account");
      this.destination = '/account';
    }

    // Check for service outages
    const status = await GetComponentStatus("MyAccount");
    if (status === "operational") {
      this.loadState = "complete";
    } else {
      this.loadState = status;
    }
  },
  methods: {
    async Continue() {
      if (this.destination && this.destination.startsWith("/")) {
        window.location.href = this.destination;
      }
    },
    async Logout() {
      try {
        await this.$store.dispatch("LogOut");
        window.location.href = "/";
      } catch (err) {
        DumpError("Logout error", err);
      }
    },
    async Login() {
      this.errorlogin = false;
      this.pendingLogin = true;
      try {
        await this.$store.dispatch("LoginUser", { username: this.username, password: this.password, stayloggedin: this.stayloggedin });
        window.location.href = this.destination;
      } catch (err) {
        if (err.response && err.response.data && err.response.data.errorCode === "AUTHENTICATION_FAILURE") {
          this.errorlogin = "The email or username and password provided are incorrect.";
        } else if (err.response && err.response.data && err.response.data.errorCode === "ACCOUNT_LOCKOUT_FAILURE") {
          this.errorlogin = err.response.data.message;
        } else {
          this.errorlogin = "There was an error logging in. Please try again later.";
        }
      } finally {
        this.pendingLogin = false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>