var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "usage-detail__container" },
    [
      _vm.testDriveUsage
        ? _c("banner-dual-action", {
            staticClass: "gds-flex-container",
            attrs: {
              bannerColor: "blue",
              message:
                "You are trying out our new usage section and we’d love to hear what you think. Click this link to provide feedback: ",
              linkOneText: "Provide Feedback.",
              messageTwo: "Or you can use this link to: ",
              linkTwoText: "Switch Back to the Previous Usage View"
            },
            on: {
              linkOneClicked: _vm.showProvideFeedBackModal,
              linkTwoClicked: _vm.goToLegacyUsage
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "usage-detail__nav-container" }, [
        _c(
          "nav",
          { staticClass: "usage-detail__router-link-container" },
          [
            _c(
              "a",
              {
                staticClass: "usage-detail__nav-item",
                attrs: { href: "/account/usage-new" }
              },
              [
                _c("div", { staticClass: "usage-detail__nav-img" }, [
                  _c(
                    "div",
                    { staticClass: "gds-icon green-svg gds-flex-container" },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "15",
                            height: "15",
                            viewBox: "0 0 15 15",
                            fill: "none"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M6 0H0V6H6V0ZM6 9H0V15H6V9ZM9 0H15V6H9V0ZM15 9H9V15H15V9Z",
                              fill: "#00704A"
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "gds-icon non-green-svg gds-flex-container"
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "15",
                            height: "15",
                            viewBox: "0 0 15 15",
                            fill: "none"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M6 0H0V6H6V0ZM6 9H0V15H6V9ZM9 0H15V6H9V0ZM15 9H9V15H15V9Z",
                              fill: "#6F7383"
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "usage-detail__nav-item__text" }, [
                    _vm._v("Dashboard")
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "usage-detail__nav-item",
                attrs: { to: "/graph" }
              },
              [
                _c("div", { staticClass: "usage-detail__nav-img" }, [
                  _c("img", {
                    staticClass: "non-green-svg",
                    attrs: {
                      src:
                        "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph.svg"
                    }
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "green-svg",
                    attrs: {
                      src:
                        "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph-green.svg"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "usage-detail__nav-item__text" }, [
                    _vm._v("Graph")
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "usage-detail__nav-item",
                attrs: { to: "/table" }
              },
              [
                _c("div", { staticClass: "usage-detail__nav-img" }, [
                  _c("img", {
                    staticClass: "non-green-svg",
                    attrs: {
                      src:
                        "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table.svg"
                    }
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "green-svg",
                    attrs: {
                      src:
                        "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table-green.svg"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "usage-detail__nav-item__text" }, [
                    _vm._v("Table")
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _vm.isNetMeteredAccountChecked &&
            typeof _vm.isNetMeteredAccount != "undefined" &&
            !_vm.isNetMeteredAccount
              ? _c(
                  "router-link",
                  {
                    staticClass: "usage-detail__nav-item",
                    attrs: { to: "/time-of-day" }
                  },
                  [
                    _c("div", { staticClass: "usage-detail__nav-img" }, [
                      _c("img", {
                        staticClass: "non-green-svg",
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day.svg"
                        }
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "green-svg",
                        attrs: {
                          src:
                            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day-green.svg"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "usage-detail__nav-item__text" },
                        [_vm._v("Time Of Day")]
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "usage-detail__nav-item",
                attrs: { to: "/performance" }
              },
              [
                _c("div", { staticClass: "usage-detail__nav-img" }, [
                  _c("img", {
                    staticClass: "non-green-svg",
                    attrs: {
                      src:
                        "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance.svg"
                    }
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "green-svg",
                    attrs: {
                      src:
                        "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance-green.svg"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "usage-detail__nav-item__text" }, [
                    _vm._v("Performance")
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "usage-detail__nav-item",
                attrs: { to: "/compare" }
              },
              [
                _c("div", { staticClass: "usage-detail__nav-img" }, [
                  _c("img", {
                    staticClass: "non-green-svg",
                    attrs: {
                      src:
                        "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare.svg"
                    }
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "green-svg",
                    attrs: {
                      src:
                        "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare-green.svg"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "usage-detail__nav-item__text" }, [
                    _vm._v("Compare")
                  ])
                ])
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("modal-floating-feedback", {
        attrs: { visible: _vm.showFeedback },
        on: {
          close: function($event) {
            _vm.showFeedback = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }