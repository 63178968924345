var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-login" }, [
    !_vm.loggedInUser
      ? _c("div", [
          _c(
            "button",
            {
              staticClass: "gds-button gds-compact gds-nav-button",
              on: { click: _vm.ToggleLoginDrawer }
            },
            [_vm._v("Log In")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loggedInUser
      ? _c("div", [
          !_vm.isMobile
            ? _c(
                "button",
                {
                  staticClass:
                    "header--user-toggle-button gds-button gds-nav-button gds-primary caret",
                  class: { "caret-down": !_vm.userMenuVisible },
                  on: { click: _vm.ToggleUserMenu }
                },
                [_vm._v(_vm._s(_vm.loggedInUser.fullName))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.userMenuVisible || _vm.isMobile
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.ToggleUserMenu,
                      expression: "ToggleUserMenu"
                    }
                  ],
                  staticClass: "header-login__settings"
                },
                [
                  _c(
                    "ul",
                    {
                      staticClass:
                        "header-login__settings__nav-list gds-body-large"
                    },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      !_vm.isAdmin && !_vm.isAdminLite
                        ? _c("li", [
                            _vm.numLinkedAccounts > 1
                              ? _c(
                                  "a",
                                  { attrs: { href: "/user-profile/accounts" } },
                                  [
                                    _vm._v(
                                      "Linked Accounts (" +
                                        _vm._s(_vm.numLinkedAccounts) +
                                        ")"
                                    )
                                  ]
                                )
                              : _c(
                                  "a",
                                  { attrs: { href: "/user-profile/accounts" } },
                                  [_vm._v("Add a Linked Account")]
                                )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      (!_vm.isAdmin || !_vm.isAdminLite) &&
                      _vm.numLinkedAccounts <= 1
                        ? _c("li", [
                            _c("a", { attrs: { href: "/account" } }, [
                              _vm._v("Account Dashboard")
                            ])
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "header-login__settings__nav-list gds-body-large"
                    },
                    [
                      (!_vm.isAdmin || !_vm.isAdminLite) &&
                      _vm.numLinkedAccounts > 1
                        ? _c(
                            "li",
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "header-login__current-account-label gds-text-grey"
                                },
                                [_vm._v("Switch Account")]
                              ),
                              _vm._v(" "),
                              _c("widget-account-switcher")
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "header-login__settings--username" },
                      [
                        _vm._v(
                          "Signed in as " + _vm._s(_vm.loggedInUser.fullName)
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "header-login__settings--email gds-space-stack-m"
                      },
                      [_vm._v(_vm._s(_vm.loggedInUser.email))]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "header-login__settings--logout gds-button gds-round gds-compact",
                        on: {
                          click: function($event) {
                            return _vm.LogOut()
                          }
                        }
                      },
                      [_vm._v("Log Out")]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.isDeveloper
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "30px",
                            "border-top": "1px solid #d8d8d8",
                            "padding-top": "10px"
                          }
                        },
                        [
                          _c("div", { staticClass: "gds-space-stack-m" }, [
                            _vm._v("Developer tools")
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "gds-button gds-small-button",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.ClearRefresh()
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "gds-button__text" }, [
                                _vm._v("Clear refresh timer")
                              ])
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "/user-profile" } }, [_vm._v("User Settings")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }