var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "my-account__sidebar__nav" },
    [
      _c(
        "router-link",
        {
          staticClass: "my-account__sidebar__nav-item",
          class: { active: _vm.subPage === "" },
          attrs: { to: "/" }
        },
        [_vm._v("Dashboard")]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "my-account__sidebar__nav-item",
          class: { active: _vm.subPage === "/billing" },
          attrs: { to: "/billing" }
        },
        [_vm._v("Billing & Payment")]
      ),
      _vm._v(" "),
      _vm.currentAccount && _vm.currentAccount.solarNetMeter
        ? _c(
            "router-link",
            {
              staticClass: "my-account__sidebar__nav-item",
              class: { active: _vm.subPage === "/netmetering" },
              attrs: { to: "/netmetering" }
            },
            [_vm._v("Net Metering")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "my-account__sidebar__nav-item",
          class: {
            active: _vm.subPage === "/usage" || _vm.subPage === "/usage-new"
          },
          attrs: { to: "/usage" }
        },
        [_vm._v("Usage")]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "my-account__sidebar__nav-item",
          class: { active: _vm.subPage === "/notifications" },
          attrs: { to: "/notifications" }
        },
        [_vm._v("Notifications")]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "my-account__sidebar__nav-item",
          class: { active: _vm.subPage === "/settings" },
          attrs: { to: "/settings" }
        },
        [_vm._v("Account Settings")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "my-account__sidebar__nav-item arrow-after-link",
          attrs: {
            href: "/start-stop-move-service",
            id: "my-account-nav__start-stop-move",
            target: "_blank",
            rel: "noopener"
          }
        },
        [_vm._v("Start, Stop, Move")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }