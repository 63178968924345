<template>
  <div class="usage-detail__component-container">
    <div class="usage-detail__usage-performance">
      <section class="gds-space-stack-l">
          <UsageDetailControls
          selectedDateWidth="300px"
          datePickerDateType="month"
          :showGoToTodayButton="true"
          :summaryText="summaryText"
          :selectedDate="selectedDate"
          :datePickerDisabledDates="disabledDates"
          :selectedDateSubText="selectedDateSubText"
          :showJumpToDateButton="showJumpToDateButton"
          :showDateForwardsButton="showDateForwardsButton"
          :showDateBackwardsButton="showDateBackwardsButton"
          :onGoToTodayClicked="onGoToTodayClicked"
          :onDateForwardsClicked="onDateForwardsClicked"
          :onSelectedDateChanged="onSelectedDateChanged"
          :onDateBackwardsClicked="onDateBackwardsClicked"
          :disableAllDateNavControls="!loadState"
          :disableDateNavigationButtonBack="disableDateNavigationButtonBack"
          :disableDateNavigationButtonForward="disableDateNavigationButtonForward"/>
      </section>
      <div v-if="!loadState" class="table-loading" style="min-height: 400px;" />
      <div class="usage-page--interior-status-container">
        <flow-error v-if="loadState === 'error'" name="Usage information" state="error" />
        <flow-error v-if="loadState === 'maintenance'" name="Usage information" state="maintenance" />
        <flow-error v-if="loadState === 'unavailable'" name="Usage information" state="unavailable"
          img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
        <flow-error v-if="loadState === 'empty'" name="Usage information" state="nodata"
          img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
      </div>
      <div v-if="loadState === 'complete'">
         <section v-if="isMobbileView || isTabletView" class="gds-space-stack-l">
          <div class="usage-detail__usage-performance__chart-legend-wrapper">
            <div class="usage-detail__usage-performance__chart-legend-items">
              <div class="usage-detail__usage-performance__chart-legend-item">
                <span class="circle current"></span>
                <div class="usage-detail__usage-performance__chart-legend-item__label-and-value">
                  <div class="usage-detail__usage-performance__chart-legend-item__label">
                    {{legendCurrentConsumptionLabel}}
                  </div>
                  <div class="usage-detail__usage-performance__chart-legend-item__value">
                    {{legendCurrentConsumptionValue}}
                  </div>
                </div>
              </div>
              <div class="usage-detail__usage-performance__chart-legend-item">
                <span class="circle previous"></span>
                <div class="usage-detail__usage-performance__chart-legend-item__label-and-value">
                  <div class="usage-detail__usage-performance__chart-legend-item__label">
                    {{legendPreviousConsumptionLabel}}
                  </div>
                  <div class="usage-detail__usage-performance__chart-legend-item__value">
                    {{legendPreviousConsumptionValue}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          </section>
        <section class="gds-space-stack-l">
          <div v-if="loadState === 'complete'"  class="my-account__usage-graph-wrapper">
            <div ref="currentUsageChartRef" class="current-usage-chart-ref"/>
          </div>
        </section>
        <section class="usage-detail__usage-performance__summary-bottom gds-space-stack-l">
          <div class="usage-detail__usage-performance__summary-text-wrapper">
            <span class="usage-detail__usage-performance__gds-icon-wrapper">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
            </span>
            <span v-html="summaryText" class="usage-detail__usage-performance__summary-text"></span>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { format, parseISO, isAfter, isToday, subDays, isWithinInterval } from "date-fns";
import GMPAPI from "../../../../services/gmpapi";
import { DrawChart } from "./usagedetailperformance";
import MediaQueryMixin from "../../../mixins/MediaQueryMixin";
import UsageDetailBaseComponentMixin from "../../../mixins/UsageDetailBaseComponentMixin";
import { DumpError, ToServerDateTruncate } from "../../../../utilities";
import UsageDetailControls from "../usagedetailcontrols/UsageDetailControls";
import { GetComponentStatus } from '../../../../services/statuspage';

export default {
  name:'PerformanceChart',
  components: {
    UsageDetailControls
  },
  mixins: [
    MediaQueryMixin,
    UsageDetailBaseComponentMixin
  ],
  async mounted() {
    this.disableAllDateNavControls = true;
    try {
       // Before we do anything else, check to see if component is operational
       const status = await GetComponentStatus("Usage");
      if (status !== "operational" && !(this.isAdmin)) {
        this.loadState = status;
        this.disableAllDateNavControls = true;
        return;
      }

      this.billingPeriods = await this.getBillingPeriods(this.currentAccount.accountNumber);
      if (!this.billingPeriods || !this.billingPeriods.periods ||  this.billingPeriods.periods.length === 0) {
        this.loadState = 'empty';
        return;
      } 

      this.disabledDates = this.disableDatePickerDatesOutsideBillingPeriods(this.billingPeriods.periods);
      
      this.getBillingCycleDates(this.billingPeriods);
      this.billingCycleDates = this.billingPeriods.periods;

      this.updateDateBackForwardButtons();
      const today = new Date();
      if (!this.previousBillingCycleDates || !this.previousBillingCycleDates.startDate) {
        this.loadState = 'unavailable';
        return;
      }
      this.checkDeviceWidthAndRefreshChart(parseISO(this.previousBillingCycleDates.startDate), today);
    
    } catch (err) {
      DumpError("Usage detail performance refresh error", err);
      if (err.response && err.response.status === 404) {
        this.loadState = 'unavailable';
      } else {
        this.loadState = "error";
      }
      return;
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    isMobbileView() {
      return this.deviceIsSmall;
    },
    isTabletView() {
      return this.deviceIsMedium
      || this.deviceIsLarge;
    },
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    }
  },
  watch: {
    isMobbileView() {
      const dates = this.getDynamicStartAndEndDates();
      this.checkDeviceWidthAndRefreshChart(dates.start, dates.end);
    },
    isTabletView() {
      const dates = this.getDynamicStartAndEndDates();
      this.checkDeviceWidthAndRefreshChart(dates.start, dates.end);
    },
    isDesktopView() {
      const dates = this.getDynamicStartAndEndDates();
      this.checkDeviceWidthAndRefreshChart(dates.start, dates.end);
    }
  },
  data() {
    return {
      summaryText: '',
      selectedDate: '',
      keyNameBack: 'back',
      selectedDateSubText: '',
      legendCurrentConsumptionLabel: 'Consumption',
      legendCurrentConsumptionValue: '',
      legendPreviousConsumptionLabel: 'Previous Consumption',
      legendPreviousConsumptionValue: '',
      showJumpToDateButton: true,
      showDateForwardsButton: true,
      showDateBackwardsButton: true,
      dailyPeriodUsageEndDate: new Date(),
      chartXAxisSteps: 2,
      loadState: undefined,
      chartDataCurrent: undefined,
      chartDataPrevious: undefined,
      totalCurrentUsage: undefined,
      totalPreviousUsage: undefined,
      allBillingCycleDates: undefined,
      currentBillingCycleDates: undefined,
      previousBillingCycleDates: undefined,
      currentBillingCycleDailyUsageData: undefined,
      previousBillingCycleDailyUsageData: undefined,
      disableDateNavigationButtonBack: false,
      disableDateNavigationButtonForward: false,
      differenceInUsage: { difference: undefined, language: undefined }
    };
  },
  methods: {
    strToNum(year, month, day) {
      const timestamp = year * 10000 + month * 100 + day;
      return timestamp;
    },
    onDateBackwardsClicked() {
      this.changeBillingCycleDates(this.keyNameBack);
    },
    onDateForwardsClicked() {
      this.changeBillingCycleDates('forward');
    },
    checkDeviceWidthAndRefreshChart(startDate, endDate) {
      this.setChartXAxisSteps();
      this.RefreshChart(startDate, endDate);
    },
    setChartXAxisSteps() {
      if (this.isMobbileView) {
        this.chartXAxisSteps = 9;
      } else if (this.isTabletView) {
        this.chartXAxisSteps = 5;
      } else if (this.isDesktopView) {
        this.chartXAxisSteps = 2;
      }
    },
    onGoToTodayClicked() {
      this.onSelectedDateChanged(new Date());
    },
    getDynamicStartAndEndDates() {
      let endDate;
      const cbcd = this.currentBillingCycleDates;

      if (cbcd.current) {
        endDate = new Date();
      } else {
        endDate = parseISO(cbcd.endDate);
      }

      return {
        end: endDate,
        start: parseISO(this.previousBillingCycleDates.startDate)
      };
    },
    getXAxisLabels(usageData, previousUsageData) {
      const xAxisLabels = [];

      usageData.forEach((usage, index) => {
        let labelString = `${format(usage.date, 'M/d')}`;

        if (index < previousUsageData.length) {
            labelString += `<br>Day ${index + 1}`;
        }

        xAxisLabels.push(labelString);
      });

      return xAxisLabels;
    },
    getCumulativeUsageDataSum(usageData) {
      let cumulativeSum = 0;
      let cumulativeArray = [];

      usageData.forEach((data) => {
        cumulativeSum += data.consumedTotal;
        cumulativeArray.push(cumulativeSum);
      });

      return cumulativeArray;
    },
    onSelectedDateChanged(selectedDate) {
      const newBillingCycleDates = this.getMatchingCurrentBillingCycle(this.allBillingCycleDates.periods, selectedDate);
      
      if (newBillingCycleDates) {
        this.previousBillingCycleDates = newBillingCycleDates.previousBillingCycleDates || newBillingCycleDates.currentBillingCycleDates;
        this.currentBillingCycleDates = newBillingCycleDates.currentBillingCycleDates;
        this.dailyPeriodUsageEndDate = parseISO(this.currentBillingCycleDates.endDate);
        
        const startDate = parseISO(this.previousBillingCycleDates.startDate || this.currentBillingCycleDates.startDate);
        const today = new Date();
        let endDate;
        if (isAfter(parseISO(this.currentBillingCycleDates.endDate), today,)) {
          endDate = today;
        } else {
          endDate =  parseISO(this.currentBillingCycleDates.endDate);
        }

        this.RefreshChart(startDate, endDate);
        this.updateDateBackForwardButtons();
        this.updateSelectedDateAndSummary(startDate, endDate);
        this.updateLegendPreviousConsumption();
      }
    },
    dateIsWithinRange(targetDate, startDate, endDate) {
      const splitString = '-';
      const end = endDate.split(splitString);
      const start = startDate.split(splitString);
      const target = targetDate.split(splitString);

      const endAsNumber =  this.strToNum(end[0], end[1], end[2]);
      const startAsNumber =  this.strToNum(start[0], start[1], start[2]);
      const targetAsNumber = this.strToNum(target[0], target[1], target[2]);

      return targetAsNumber >= startAsNumber && targetAsNumber <= endAsNumber;
    },
    parseDailyUsageData(dailyUsageData, startDate, endDate) {
      const dateFormat = 'yyyy-MM-dd';
      const startDateFormatted = format(startDate, dateFormat);
      const endDateFormatted = format(endDate, dateFormat);

      const filteredArr = dailyUsageData.intervals[0].values.filter(value => {
        const dateFormatted = format(value.date, dateFormat);
        return this.dateIsWithinRange(dateFormatted, startDateFormatted, endDateFormatted);
      });

      return filteredArr;
    },
    updateSelectedDateAndSummary(startDate, endDate) {
      const dateFormatMdy = 'MMM dd, yyyy';
      this.selectedDate = format(endDate, 'MMMM yyyy');
      this.selectedDateSubText = `${format(startDate, dateFormatMdy)} - ${format(endDate, dateFormatMdy)}`;
      this.legendCurrentConsumptionValue = this.selectedDateSubText;
    },
    updateLegendPreviousConsumption() {
      const dateFormatMdy = 'MMM dd, yyyy';
      if (this.previousBillingCycleDates) {
        this.legendPreviousConsumptionValue = `${format(parseISO(this.previousBillingCycleDates.startDate), dateFormatMdy)} - ${format(parseISO(this.previousBillingCycleDates.endDate), dateFormatMdy)}`;
      }
    },
    getTotalCycleUsage(chartData) {
      let totalUsage = 0;
      chartData.forEach((data) => totalUsage += data.consumedTotal);

      return parseFloat(totalUsage.toFixed(1));
    },
    getPrevBillingCycleTotalUsageVsCurrentBillingCycle(prevChartData, currChartData) {
      let totalUsage = 0;

      prevChartData.forEach((data, index) => {
        if (index < currChartData.length) {
          totalUsage += data.consumedTotal;
        }
      });

      return parseFloat(totalUsage.toFixed(1));
    },
    getCumulativeUsageDataSum(usageData) {
      let cumulativeArray = [];
      let cumulativeSum = 0;

      usageData.forEach(data => {
        cumulativeSum += data.consumedTotal;
        cumulativeArray.push(cumulativeSum);
      });

      return cumulativeArray;
    },
    getDifferenceInUsage(previousUsage, currentUsage) {
      if (currentUsage > previousUsage) {
        return {
          difference: Math.round(currentUsage - previousUsage),
          language: 'more'
        }
      } else {
        return {
          difference: Math.round(previousUsage - currentUsage),
          language: 'less'
        }
      }
    },
    updateSummaryText() {
      const diu = this.differenceInUsage;
      const dateFormatted = format(this.dailyPeriodUsageEndDate, 'MMMM d');
      const differenceFormatted = parseFloat((diu.difference).toFixed(0)).toLocaleString();

      if (this.currentBillingCycleDates.current) {
        this.summaryText = `By ${dateFormatted}, you used <span class="strong">${differenceFormatted} kWh ${diu.language}</span>  than previous billing period up to this point.`;
      } else {
        this.summaryText = `For the billing period ending ${dateFormatted}, you used <span class="strong">${differenceFormatted} kWh ${diu.language}</span>  than previous billing period.`;
      }
    },
    updateDateBackForwardButtons() {
      if (this.previousBillingCycleDates) {
        this.disableDateNavigationButtonBack = this.previousBillingCycleDates.earliest ? true : false;
      } else {
        this.disableDateNavigationButtonBack = true;
      }

      if (this.currentBillingCycleDates) {
        this.disableDateNavigationButtonForward = this.currentBillingCycleDates.current ? true : false;
      } else {
        this.disableDateNavigationButtonForward = true;
      }
    },
    async getBillingCycleDates(data) {
      this.allBillingCycleDates = JSON.parse(JSON.stringify(data));
      this.allBillingCycleDates.periods[this.allBillingCycleDates.periods.length - 1].earliest = true;

      this.allBillingCycleDates.periods.forEach(period => {
        if (period.current) { this.currentBillingCycleDates = period; }
        if (period.last) { this.previousBillingCycleDates = period; }
      });

      if (isToday(parseISO(this.currentBillingCycleDates.startDate))) {
        this.currentBillingCycleDates = this.previousBillingCycleDates;
        const searchDateForNewPrevious = subDays(parseISO(this.previousBillingCycleDates.startDate), 1);

        this.allBillingCycleDates.periods.forEach(period => {
          if (isWithinInterval(searchDateForNewPrevious, {
            start: parseISO(period.startDate),
            end: parseISO(period.endDate)
          })) {
            this.previousBillingCycleDates = period;
          }
        });
      }
    },

    changeBillingCycleDates(direction) {
      let target;

      if (direction === this.keyNameBack) {
        target = this.currentBillingCycleDates.startDate;
      } else {
        target = this.previousBillingCycleDates ? this.previousBillingCycleDates.startDate : this.currentBillingCycleDates.startDate;
      }

      const newData = this.updateBillingCycleDates(direction, this.allBillingCycleDates.periods, target);
      if (newData) {
        this.previousBillingCycleDates = newData.previousBillingCycleDates || newData.currentBillingCycleDates;
        this.currentBillingCycleDates = newData.currentBillingCycleDates;
        this.dailyPeriodUsageEndDate = parseISO(this.currentBillingCycleDates.endDate);

        const startDate = newData.previousBillingCycleDates ?
                            newData.previousBillingCycleDates.startDate : newData.currentBillingCycleDates.startDate;
        let endDate = parseISO(newData.currentBillingCycleDates.endDate);
        const today = new Date();
        if (isAfter(endDate, today)) {
          endDate = today;
        } 

        this.RefreshChart(parseISO(startDate), endDate);
        this.updateDateBackForwardButtons();
        this.updateSelectedDateAndSummary(parseISO(this.currentBillingCycleDates.startDate), endDate);
        this.updateLegendPreviousConsumption();
      }
    },
    updateBillingCycleDates(direction, billingCycleDates, currentStartDate) {
        const index = billingCycleDates.findIndex(obj => obj.startDate === currentStartDate);
        const indexChangeCurrent = (direction === this.keyNameBack) ? index + 1 : index - 2;
        const indexChangePrevious = (direction === this.keyNameBack) ? index + 2 : index - 1;

        if (index === -1 || index === 0) {
          return [];
        } else {
          return {
            currentBillingCycleDates: billingCycleDates[indexChangeCurrent],
            previousBillingCycleDates: billingCycleDates[indexChangePrevious]
          }
        }
    },
    getMatchingCurrentBillingCycle(allBillingCycleDates, searchDate) {
      let currentBillingCycleDates = null;
      let previousBillingCycleDates = null;

      allBillingCycleDates.forEach((cycle, index) => {
        if (isWithinInterval(searchDate, {
          start: parseISO(cycle.startDate), end: parseISO(cycle.endDate)
        })) {
          if (isToday(parseISO(cycle.startDate))) {
            currentBillingCycleDates = allBillingCycleDates[index + 1] || null;
            previousBillingCycleDates = allBillingCycleDates[index + 2] || null;
          } else {
            currentBillingCycleDates = cycle;
            previousBillingCycleDates = allBillingCycleDates[index + 1] || null;
          }
        }
      });

      if (currentBillingCycleDates) {
        return {
          currentBillingCycleDates,
          previousBillingCycleDates
        };
      }

      return undefined;
    },
    async getDailyPeriodUsageData(startDate, endDate) {
      this.disableAllDateNavControls = true;
      const dailyUsageData = await GMPAPI.GetPeriodUsage(this.currentAccount.accountNumber, 'daily', ToServerDateTruncate(startDate), ToServerDateTruncate(endDate));
      this.disableAllDateNavControls = false;

      if (dailyUsageData && dailyUsageData.intervals[0] && dailyUsageData.intervals[0].values.length > 0) {
          if (!this.hasConsumption(dailyUsageData)) {
            this.loadState = 'empty';
            return [];
          }
      }
      return dailyUsageData;
    },
    async RefreshChart(startDate, endDate) {
      this.summaryText = '';
      this.loadState = undefined;

      let dailyUsageData;
      try {
        dailyUsageData = await this.getDailyPeriodUsageData(startDate, endDate);
        if (!dailyUsageData || !dailyUsageData.intervals || dailyUsageData.intervals[0].values.length === 0) {
          this.loadState = 'empty';
          return;
        } 
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.errorCode === "MDM_NO_ACTIVE_SERVICES") {
          this.loadState = "unavailable";
        } else if (err && err.response && err.response.data && err.response.data.errorCode === "NO_DATA_FOUND" || err.response && err.response.status === 404) {
          this.loadState = 'empty';
        } else {
          this.handleRefreshError(err);
        }
        this.disableAllDateNavControls = false;
        return;
      }
      this.updateDateBackForwardButtons();
      this.updateSelectedDateAndSummary(startDate, endDate);

      this.loadState = 'complete';
      if (this.previousBillingCycleDates) {
        this.previousBillingCycleDailyUsageData = this.parseDailyUsageData(dailyUsageData, parseISO(this.previousBillingCycleDates.startDate), parseISO(this.previousBillingCycleDates.endDate));
        this.chartDataPrevious = this.getCumulativeUsageDataSum(this.previousBillingCycleDailyUsageData);
      }

      if (this.currentBillingCycleDates) {
        this.currentBillingCycleDailyUsageData = this.parseDailyUsageData(dailyUsageData, parseISO(this.currentBillingCycleDates.startDate), parseISO(this.currentBillingCycleDates.endDate));
        this.chartDataCurrent = this.getCumulativeUsageDataSum(this.currentBillingCycleDailyUsageData);
      }

      if (!this.currentBillingCycleDates.current) {
        this.totalPreviousUsage = this.getTotalCycleUsage(this.previousBillingCycleDailyUsageData);
      } else {
        this.totalPreviousUsage = this.getPrevBillingCycleTotalUsageVsCurrentBillingCycle(this.previousBillingCycleDailyUsageData, this.currentBillingCycleDailyUsageData);
      }

      this.totalCurrentUsage = this.getTotalCycleUsage(this.currentBillingCycleDailyUsageData);
      this.differenceInUsage = this.getDifferenceInUsage(this.totalPreviousUsage, this.totalCurrentUsage);

      const xAxisLabels = this.getXAxisLabels(this.currentBillingCycleDailyUsageData, this.previousBillingCycleDailyUsageData);

      this.updateSelectedDateAndSummary(parseISO(this.currentBillingCycleDates.startDate), endDate);
      this.updateSummaryText();
      await this.$nextTick();

      DrawChart(this.$refs.currentUsageChartRef, this.chartDataPrevious, this.chartDataCurrent, this.previousBillingCycleDailyUsageData, this.currentBillingCycleDailyUsageData, xAxisLabels, this.chartXAxisSteps, '#EF6723');
    } 
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>