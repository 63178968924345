<template>
  <div class="my-account__usage-graph-wrapper" :style="{ 'min-height': height }">
    <div ref="usageGraphRef" />
    <div v-if="!usageData" class="my-account__usage-loading" />
    <div v-if="noData" class="my-account__usage-nodata">No data for the selected interval.</div>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { differenceInMilliseconds } from "date-fns";
import { FormatChartData } from "./usagedata";

export default {
  name: "UsageChart",
  props: {
    usageData: Object,
    mini: Boolean,
    height: String,
    selected: Number,
    layers: Array
  },
  data() {
    return {
      /** Holds reference to the highcharts chart object */
      chart: undefined,
      /** Holds an array of the bin dates */
      dates: undefined,
      lastClickTime: undefined,
      lastClickIndex: undefined,
      noData: false,
    };
  },
  async mounted() {
    this.FormatUsageData();
  },
  methods: {
    ChartLoaded() {
      // Once the chart has been loaded, we have to apply the styles from the layers/selected props
      this.RefreshLayers();
      this.RefreshSelected(this.selected);
    },
    /** Refresh which intervals are highlighted vs inactive */
    RefreshSelected(index) {
      if (this.chart && this.chart.series && this.chart.series.length) {
        if (index === undefined) {
          // Deselect all points
          for (const series of this.chart.series) {
            for (const point of series.data) {
              point.select(false);
            }
          }
        } else {
          // Select all points for this index, deselect the rest
          for (const series of this.chart.series) {
            for (const [x, point] of series.data.entries()) {
              if (x === index) {
                point.select(true, true);
              } else {
                point.select(false, true);
              }
            }
          }
          // Special case - if we are selecting the last interval, scroll to the end of the axis
          if (index === this.dates.length - 1) {
            this.ScrollFarRight();
          }
        }
      }
    },
    /** Update the visibility of the selected rate layers */
    RefreshLayers() {
      if (this.chart && this.chart.series && this.chart.series.length) {
        for (const series of this.chart.series) {
          if (this.layers.includes(series.name) && !(this.mini && series.name === "Temperature")) {
            if (!series.visible) {
              series.setVisible(true);
            }
          } else {
            if (series.visible) {
              series.setVisible(false);
            }
          }
        }
      }
    },
    ClickInterval(index) {
      // Debounce for double click
      const now = new Date();
      if (index === this.lastClickIndex && differenceInMilliseconds(now, this.lastClickTime) < 500) {
        // Pass click up to parent
        this.$emit("click", index);
      } else {
        this.lastClickTime = now;
        this.lastClickIndex = index;
      }
    },
    /** Scroll all the way to the right */
    async ScrollFarRight() {
      // Wait a tick for the chart to be rendered
      await this.$nextTick();
      if (this.chart.scrollingContainer) {
        const maximum = this.chart.scrollingContainer.scrollWidth;
        this.chart.scrollingContainer.scrollLeft = maximum;
      }
    },
    /** Try to scroll right or left. If out of scroll, emit an interval adjustment */
    Navigate(direction) {
      if (!this.chart.scrollingContainer) {
        // We aren't scrolling (window is wide enough to contain entire chart), so just emit
        this.$emit("IntervalAdjust", direction);
      } else {
        const left = this.chart.scrollingContainer.scrollLeft;
        const width = this.chart.scrollingContainer.clientWidth;
        const maximum = this.chart.scrollingContainer.scrollWidth - width;
        const margin = this.chart.plotBox.x + 10;

        if (direction === "right") {
          if (left < maximum) {
            // We've got room to scroll right
            this.chart.scrollingContainer.scrollLeft += (width - margin);
          } else {
            this.$emit("IntervalAdjust", "right");
          }
        } else {
          if (left > 0) {
            // We've got room to scroll left
            this.chart.scrollingContainer.scrollLeft -= (width - margin);
          } else {
            this.$emit("IntervalAdjust", "left");
          }
        }
      }
    },
    FormatUsageData() {
      this.noData = false;
      if (!this.usageData || !this.usageData.bins) {
        // Leave the chart there for now so we don't break hanging hover callbacks etc
      } else {
        // This generates the series and labels to be inserted into the options
        const formatted = FormatChartData(this.usageData.bins, this.usageData.interval, this.mini, true);
        if (formatted.labels.length === 0) {
          this.noData = true;
        }
        const options = {
          chart: {
            height: this.height,
            marginTop: 20,
            marginBottom: 70,
            marginLeft: 50,
            marginRight: -2,
            scrollablePlotArea: {
              minWidth: formatted.labels.length * 50,
            },
            followTouchMove: false,
            animation: false,
            events: {
              click: e => {
                this.ClickInterval(this.chart.hoveredIndex);
              },
            },
            style: {
              fontFamily: "MaisonNeue, Helvetica, sans-serif",
            },
          },
          title: {
            text: undefined,
          },
          legend: {
            enabled: false,
          },
          credits: false,
          plotOptions: {
            column: {
              stacking: "normal",
              borderWidth: 0,
              groupPadding: 0.1,
              events: {
                click: e => {
                  this.ClickInterval(this.chart.hoveredIndex);
                },
              },
            },
          },
          xAxis: {
            categories: formatted.labels,
            labels: {
              style: {
                fontSize: "13px",
              },
            },
          },
          yAxis: [{
            title: {
              text: undefined
            },
            gridLineWidth: 0.5,
            gridZIndex: 4,
            gridLineColor: 'rgba(51, 51, 51, 0.2)',
            className: 'usage-graph-y-axis-label',
            tickInterval: undefined,
            offset: 50,
            labels: {
              enabled: true,
              align: 'left',
              useHTML: true,
              formatter: function () {
                let formattedValue = undefined;
                formattedValue = this.isLast ? `${this.value} kWh` : this.value;
                return `<i class='value'>${formattedValue}</i>`;
              }
            }
          },
          {
            title: "Temperature",
            color: "#000",
            opposite: true,
            className: 'usage-graph-y-axis-label',
            labels: {
              formatter: e => {
                return e.value + "°F";
              }
            }
          }],
          tooltip: {
            shared: true,
            borderWidth: 0,
            backgroundColor: "transparent",
            borderRadius: 0,
            shadow: false,
            useHTML: true,
            formatter: function (tooltip) {
              const index = tooltip.chart.xAxis[0].categories.indexOf(this.x);
              // Update the hover index which we will use for clicking later
              tooltip.chart.hoveredIndex = index;
              const date = this.x.replace("<br>", " ");
              let html = "<div class='my-account__usage-tooltip'><div class='tooltip__month-year gds-flex-container gds-flex-container--left'>" + date + "</div>";
              for (const point of this.points) {
                let color = point.color;
                if (point.color.stops) {
                  color = point.color.stops[0][1];
                }
                const rounded = point.y.toFixed(1);
                html += "<div class='tooltip-rate gds-flex-container gds-flex-container--space-between'><div class='gds-flex-container gds-flex-container--left'><div class='rate-swatch' style='background-color: " + color + "'></div><div class='rate-name'>" + point.series.name + "</div></div><div class='rate-value'>" + "&nbsp" + rounded + point.series.userOptions.measurement + "</div></div>";
              }
              html += "</div>";
              return html;
            },
            valueDecimals: 2,
            outside: true,
          },
          series: formatted.series,
        };

        this.dates = formatted.dates;
        // media query to adjust Yaxis labels on small screens
        let mql = window.matchMedia("screen and (max-width: 580px)");
        if (mql.matches) { // if media query matches
          options.yAxis[0].labels.reserveSpace = false;
          options.yAxis[0].labels.align = "left";
          options.yAxis[0].labels.x = 0;
          options.yAxis[0].labels.y = -1;
          options.yAxis[1].labels.reserveSpace = false;
          options.yAxis[1].labels.align = "right";
          options.yAxis[1].labels.x = 0;
          options.yAxis[1].labels.y = -1;
        }
        this.chart = Highcharts.chart(this.$refs.usageGraphRef, options);
        this.RefreshLayers();
        this.RefreshSelected(this.selected);
      }
    },
  },
  watch: {
    usageData() {
      this.FormatUsageData();
    },
    layers() {
      this.RefreshLayers();
    },
    selected() {
      this.RefreshSelected(this.selected);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>